$sidebar-width: 230px;
@media (min-width: 1200px){
    .nk-sidebar {
        width: 230px;
    }
    .nk-sidebar + .nk-wrap{
        padding-left: 230px;
    }
    .nk-sidebar + .nk-wrap > .nk-header-fixed{
        left: 230px;
    }
}

@import '~react-pro-sidebar/dist/scss/styles.scss';