/*
Theme Name: Panagea v.2.8
Theme URI: http://www.ansonika.com/panagea/
Author: Ansonika
Author URI: http://themeforest.net/user/Ansonika/

[Table of contents] 

1. SITE STRUCTURE and TYPOGRAPHY
- 1.1 Typography
- 1.2 Buttons
- 1.3 Structure

2. CONTENT
- 2.1 Home
- 2.2 About
- 2.3 Listing
- 2.4 Detail page
- 2.5 Login/register
- 2.6 Map styles
- 2.7 Contacts
- 2.8 404 page 
- 2.9 Cart section
- 2.10 Media Gallery
- 2.11 Faq
- 2.12 Adventure
- 2.13 Home AirBnb
- 2.14 Half screen map
- 2.15 Autocomplete Address Search
- 2.16 OpenStreet Map
- 2.17 Parallax Video Background
- 2.18 Other Headers
- 2.19 Home Categories Hover effect
- 2.20 Home Search Radio categories

3. COMMON
- 3.1 Misc
- 3.2 Accordion
- 3.3 List
- 3.4 Spacing
- 3.5 Block reveal
- 3.6 Cookie bar
- 3.7 Sing In Modal
- 3.8 Input Switcher
- 3.9 Datepicker
- 3.10 Modal Generic
- 3.11 Others pricing tables
- 3.12 Styles Switcher
- 3.13 Time picker

/*============================================================================================*/
/* 1.  SITE STRUCTURE and TYPOGRAPHY */
/*============================================================================================*/
/* MAIN NAVIGATION */
/* All styles for screen size over 992px*/
@media only screen and (min-width: 992px) {
  nav#menu.main-menu {
    display: block !important;
  }

  #mm-menu.main-menu {
    display: none !important;
  }

  header .btn_mobile {
    display: none !important;
  }

  /* Menu */
  .main-menu {
    position: relative;
    z-index: 9;
    width: auto;
    top: 12px;
    right: 15px;
    float: right;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    color: #fff;
  }
  .main-menu ul,
  .main-menu ul li {
    position: relative;
    display: inline-block;
    margin: 0;
    padding: 0;
  }
  .main-menu ul a,
  .main-menu ul li a {
    position: relative;
    margin: 0;
    padding: 0;
    display: block;
    padding: 5px 10px;
    white-space: nowrap;
  }

  /*First level styles */
  .main-menu > ul > li span > a {
    color: #fff;
    padding: 0 8px 15px 8px;
    font-size: 14px;
    font-size: 0.875rem;
    font-weight: 500;
  }

  header.sticky .main-menu > ul > li span > a {
    color: #444;
  }
  header.sticky .main-menu > ul > li span:hover > a {
    opacity: 1;
    color: #fc5b62;
  }

  /* Submenu*/
  .main-menu ul ul {
    position: absolute;
    z-index: 1;
    visibility: hidden;
    left: 3px;
    top: 100%;
    margin: 0;
    display: block;
    padding: 0;
    background: #fff;
    min-width: 200px;
    -webkit-box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.175);
    -moz-box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.175);
    box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.175);
    transform: scale(0.4);
    transform-origin: 10% top;
    transition: 0.15s linear, 0.1s opacity cubic-bezier(0.39, 0.575, 0.565, 1), 0.15s transform cubic-bezier(0.1, 1.26, 0.83, 1);
    opacity: 0;
    -moz-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    -webkit-transition: all 0.2s ease;
    -ms-transition: all 0.2s ease;
    transition: all 0.2s ease;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
  }
  .main-menu ul ul:before {
    bottom: 100%;
    left: 15%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-bottom-color: #fff;
    border-width: 7px;
    margin-left: -7px;
  }
  .main-menu ul ul li {
    display: block;
    height: auto;
    padding: 0;
  }
  .main-menu ul ul li a {
    font-size: 12px;
    font-size: 0.75rem;
    font-weight: 500;
    color: #555;
    border-bottom: 1px solid #ededed;
    display: block;
    padding: 15px 10px;
    line-height: 1;
  }

  .main-menu ul li:hover > ul {
    padding: 0;
    visibility: visible;
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition-delay: 0.2s;
    transition-delay: 0.2s;
    -webkit-transition-duration: 0s, 0.2s, 0.2s;
    transition-duration: 0s, 0.2s, 0.2s;
  }

  .main-menu ul ul li:first-child a:hover {
    -webkit-border-radius: 3px 3px 0 0;
    -moz-border-radius: 3px 3px 0 0;
    -ms-border-radius: 3px 3px 0 0;
    border-radius: 3px 3px 0 0;
  }
  .main-menu ul ul li:last-child a {
    border-bottom: none;
  }
  .main-menu ul ul li:last-child a:hover {
    -webkit-border-radius: 0 0 3px 3px;
    -moz-border-radius: 0 0 3px 3px;
    -ms-border-radius: 0 0 3px 3px;
    border-radius: 0 0 3px 3px;
  }
  .main-menu ul ul li:hover > a {
    background-color: #f9f9f9;
    color: #fc5b62;
    padding-left: 18px;
  }
}
/* Submenu 2nd level right */
.main-menu ul ul.second_level_right {
  left: auto;
  right: 3px;
}
.main-menu ul ul.second_level_right:before {
  right: 15%;
  left: auto;
}

/* Submenu 3rd level */
.main-menu ul ul li span a {
  font-weight: 500 !important;
}
.main-menu ul ul li span a:hover {
  color: #fc5b62;
  padding-left: 18px;
  opacity: 1;
}
.main-menu ul ul li span a:after {
  font-family: 'ElegantIcons';
  content: "\35";
  float: right;
  font-size: 16px;
  font-size: 1rem;
  margin-top: -2px;
}

.main-menu ul ul ul {
  position: absolute;
  border-top: 0;
  z-index: 1;
  height: auto;
  left: 100%;
  top: 0;
  margin: 0;
  padding: 0;
  background: #fff;
  min-width: 190px;
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}
.main-menu ul ul ul.third_level_left {
  right: 100%;
  left: auto;
}
.main-menu ul ul ul li a {
  border-bottom: 1px solid #ededed !important;
}
.main-menu ul ul ul li:last-child a {
  border-bottom: none !important;
}

/* Arrows top 3rd level*/
.main-menu ul ul ul:before {
  border-width: 0;
  margin-left: 0;
}

/* All styles for screen size under 991px */
@media only screen and (max-width: 991px) {
  nav#menu {
    display: none !important;
  }

  .mm-menu {
    background: #fff;
  }

  .main-menu {
    top: 0;
    right: 0;
    float: none;
  }

  ul.mm-listview {
    line-height: 25px;
  }
  ul.mm-listview li a {
    color: #555 !important;
    display: block;
  }
}
#menu.fake_menu {
  display: none !important;
  visibility: hidden !important;
}

/*-------- Preloader --------*/
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  bottom: 0;
  background-color: #fff;
  z-index: 99999999999 !important;
}

[data-loader="circle-side"] {
  position: absolute;
  width: 50px;
  height: 50px;
  top: 50%;
  left: 50%;
  margin-left: -25px;
  margin-top: -25px;
  -webkit-animation: circle infinite .95s linear;
  -moz-animation: circle infinite .95s linear;
  -o-animation: circle infinite .95s linear;
  animation: circle infinite .95s linear;
  border: 2px solid #fc5b62;
  border-top-color: rgba(0, 0, 0, 0.2);
  border-right-color: rgba(0, 0, 0, 0.2);
  border-bottom-color: rgba(0, 0, 0, 0.2);
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  border-radius: 100%;
}

@-webkit-keyframes circle {
  0% {
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes circle {
  0% {
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-o-keyframes circle {
  0% {
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes circle {
  0% {
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
/*-------- 1.1 Typography --------*/
/* rem reference
10px = 0.625rem
12px = 0.75rem
14px = 0.875rem
16px = 1rem (base)
18px = 1.125rem
20px = 1.25rem
24px = 1.5rem
30px = 1.875rem
32px = 2rem
*/
html * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background: #f8f8f8;
  font-size: 0.875rem;
  line-height: 1.6;
  font-family: "Poppins", Helvetica, sans-serif;
  color: #555;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #333;
}

p {
  margin-bottom: 30px;
}

strong {
  font-weight: 600;
}

label {
  font-weight: 500;
  margin-bottom: 3px;
}

hr {
  margin: 30px 0 30px 0;
  border-color: #ededed;
  opacity: 1;
}
hr.large {
  border-color: #ededed;
  height: 4px;
  margin-top: 60px;
}
@media (max-width: 991px) {
  hr.large {
    margin-top: 45px;
  }
}
@media (max-width: 767px) {
  hr.large {
    margin-bottom: 45px;
  }
}

ul, ol {
  list-style: none;
  margin: 0 0 25px 0;
  padding: 0;
}

.main_title {
  text-align: center;
  margin-bottom: 45px;
  position: relative;
}

.main_title span {
  content: "";
  display: block;
  width: 60px;
  height: 3px;
  margin: auto;
  margin-bottom: 20px;
  opacity: 1;
  background-color: #999;
  visibility: hidden;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transition: all 0.6s;
  transition: all 0.6s;
}

.main_title.animated span {
  visibility: visible;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

.main_title_2 {
  text-align: center;
  margin-bottom: 45px;
  position: relative;
}
.main_title_2 span {
  width: 120px;
  height: 2px;
  background-color: #e1e1e1;
  display: block;
  margin: auto;
}
.main_title_2 span em {
  width: 60px;
  height: 2px;
  background-color: #fc5b62;
  display: block;
  margin: auto;
}
@media (max-width: 767px) {
  .main_title_2 {
    margin-bottom: 10px;
  }
}
.main_title_2 h2 {
  margin: 25px 0 0 0;
}
@media (max-width: 767px) {
  .main_title_2 h2 {
    font-size: 26px;
    font-size: 1.625rem;
  }
}
.main_title_2 h3 {
  margin: 25px 0 0 0;
}
@media (max-width: 767px) {
  .main_title_2 h3 {
    font-size: 24px;
    font-size: 1.5rem;
  }
}
.main_title_2 p {
  font-size: 24px;
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 1.4;
}
@media (max-width: 767px) {
  .main_title_2 p {
    font-size: 18px;
    font-size: 1.125rem;
  }
}

.main_title_3 {
  margin-bottom: 25px;
  position: relative;
}
.main_title_3 span {
  width: 120px;
  height: 2px;
  background-color: #e1e1e1;
  display: block;
}
.main_title_3 span em {
  width: 60px;
  height: 2px;
  background-color: #fc5b62;
  display: block;
}
@media (max-width: 767px) {
  .main_title_3 {
    margin-bottom: 10px;
  }
}
.main_title_3 h2, .main_title_3 h3 {
  font-size: 23px;
  font-size: 1.4375rem;
  margin: 25px 0 0 0;
}
@media (max-width: 767px) {
  .main_title_3 h2, .main_title_3 h3 {
    font-size: 21px;
    font-size: 1.3125rem;
  }
}
.main_title_3 p {
  font-size: 18px;
  font-size: 1.125rem;
  font-weight: 300;
  line-height: 1.4;
}

/** Layes slider typography ab buttons **/
h3.slide_typo {
  white-space: nowrap;
  color: #fff !important;
  font-weight: normal;
  font-size: 64px;
  font-size: 4rem;
}

.slide_typo_2 {
  color: #fff;
  font-size: 28px;
  font-size: 1.75rem;
  white-space: nowrap;
  text-transform: uppercase;
}

.ls-l a.btn_1 {
  white-space: nowrap;
}
@media (max-width: 767px) {
  .ls-l a.btn_1 {
    font-size: 11px !important;
    padding: 7px 15px !important;
  }
}

/*General links color*/
a {
  color: #fc5b62;
  text-decoration: none;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  outline: none;
}
a:hover, a:focus {
  color: #111;
  text-decoration: none;
  outline: none;
}

/*-------- 1.2 Buttons --------*/
a.btn_1,
.btn_1 {
  border: none;
  color: #fff;
  background: #fc5b62;
  outline: none;
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  padding: 15px 30px;
  color: #fff;
  font-weight: 600;
  text-align: center;
  line-height: 1;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
}
a.btn_1:hover,
.btn_1:hover {
  background: #0054a6;
  color: #fff;
}
a.btn_1.full-width,
.btn_1.full-width {
  display: block;
  width: 100%;
  text-align: center;
  margin-bottom: 5px;
}
a.btn_1.full-width.purchase,
.btn_1.full-width.purchase {
  background-color: #32a067;
}
a.btn_1.full-width.purchase:hover,
.btn_1.full-width.purchase:hover {
  background-color: #0054a6;
}
a.btn_1.full-width.wishlist,
.btn_1.full-width.wishlist {
  border-color: #555;
  color: #555;
}
a.btn_1.medium,
.btn_1.medium {
  font-size: 16px;
  font-size: 1rem;
  padding: 18px 40px;
}
a.btn_1.rounded,
.btn_1.rounded {
  -webkit-border-radius: 25px !important;
  -moz-border-radius: 25px !important;
  -ms-border-radius: 25px !important;
  border-radius: 25px !important;
}
a.btn_1.outline,
.btn_1.outline {
  border: 2px solid #fc5b62;
  color: #fc5b62;
  padding: 11px 40px;
  background: none;
}
a.btn_1.outline:hover,
.btn_1.outline:hover {
  background: #0054a6;
  color: #fff;
  border-color: #0054a6;
}
a.btn_1.outline:focus,
.btn_1.outline:focus {
  outline: none;
}

.closebt {
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  position: absolute;
  font-size: 24px;
  font-size: 1.5rem;
  top: 10px;
  right: 15px;
  color: #fff;
  opacity: 0.6;
  width: 30px;
  height: 30px;
}
.closebt:hover {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
  opacity: 1;
}

.btn_home_align {
  text-align: right;
  margin-bottom: 25px;
}
@media (max-width: 767px) {
  .btn_home_align {
    text-align: center;
    margin-bottom: 25px;
  }
}

a.btn_map {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  padding: 8px 12px 8px 12px;
  line-height: 1;
  margin-bottom: 5px;
  display: inline-block;
  border: 1px solid rgba(0, 0, 0, 0.08);
  font-weight: 600;
  font-size: 12px;
  font-size: 0.75rem;
  color: rgba(0, 0, 0, 0.5);
}
a.btn_map:hover {
  color: #fc5b62;
}
a.btn_map:before {
  font-family: 'ElegantIcons';
  content: "\e081";
  display: inline-block;
  margin-right: 5px;
}

a.btn_map_in,
.btn_map_in {
  border: none;
  color: #fff;
  background: #fc5b62;
  outline: none;
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  padding: 15px 30px;
  color: #fff;
  font-weight: 600;
  text-align: center;
  width: 100%;
  margin-bottom: 20px;
  display: inline-block;
  line-height: 1;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
}
a.btn_map_in:hover,
.btn_map_in:hover {
  background: #0054a6;
  color: #fff;
}

/*-------- 1.3 Structure --------*/
/* Header */
header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  padding: 20px 30px;
  /* z-index: 99999999; */
  z-index: 2;
  border-bottom: 1px solid rgba(255, 255, 255, 0);
}
@media (max-width: 767px) {
  header {
    padding: 15px 15px 5px 15px;
  }
}
header.header .logo_sticky {
  display: none;
}
header.header.sticky {
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  border-bottom: 1px solid #ededed;
  background-color: #fff;
  padding: 15px 20px;
}
@media (max-width: 991px) {
  header.header.sticky {
    padding: 15px 15px 5px 15px;
  }
}
header.header.sticky .logo_normal {
  display: none;
}
header.header.sticky .logo_sticky {
  display: inline-block;
}

ul#top_menu {
  float: right;
  margin: 0 0 0 10px;
  padding: 0;
  font-size: 13px;
  font-size: 0.8125rem;
}
@media (max-width: 991px) {
  ul#top_menu {
    margin: -2px 0 0 10px;
  }
}
ul#top_menu li {
  float: left;
  margin: 0;
  line-height: 1;
  margin-right: 15px;
}
ul#top_menu li:last-child {
  margin-right: 0;
  position: relative;
  top: 0;
}
@media (max-width: 991px) {
  ul#top_menu li {
    margin: 0 0 0 10px;
  }
}
@media (max-width: 767px) {
  ul#top_menu li:first-child {
    display: none;
  }
}
ul#top_menu li a {
  color: #fff;
}
ul#top_menu li a:hover {
  color: #fff;
  opacity: 0.7;
}
ul#top_menu li a.login, ul#top_menu li a.wishlist_bt_top, ul#top_menu li a.cart-menu-btn {
  display: block;
  width: 22px;
  height: 23px;
  position: relative;
  top: 8px;
  -moz-transition: opacity 0.5s ease;
  -o-transition: opacity 0.5s ease;
  -webkit-transition: opacity 0.5s ease;
  -ms-transition: opacity 0.5s ease;
  transition: opacity 0.5s ease;
}
@media (max-width: 991px) {
  ul#top_menu li a.login, ul#top_menu li a.wishlist_bt_top, ul#top_menu li a.cart-menu-btn {
    top: 2px;
  }
}
ul#top_menu li a.login:before, ul#top_menu li a.wishlist_bt_top:before, ul#top_menu li a.cart-menu-btn:before {
  font-family: 'Glyphter';
  font-size: 21px;
  font-size: 1.3125rem;
  color: #fff;
  text-indent: 0;
  position: absolute;
  left: 0;
  top: 0;
  font-weight: normal;
  line-height: 1;
}
.sticky ul#top_menu li a.login:before, .sticky ul#top_menu li a.wishlist_bt_top:before, .sticky ul#top_menu li a.cart-menu-btn:before {
  color: #444;
}
ul#top_menu li a.wishlist_bt_top {
  text-indent: -9999px;
}
ul#top_menu li a.wishlist_bt_top:before {
  content: '\0043';
}
ul#top_menu li a.login {
  text-indent: -9999px;
}
ul#top_menu li a.login:before {
  content: '\0042';
}
ul#top_menu li a.cart-menu-btn {
  top: 6px;
}
@media (max-width: 991px) {
  ul#top_menu li a.cart-menu-btn {
    top: 0;
  }
}
ul#top_menu li a.cart-menu-btn:before {
  content: '\0041';
}
ul#top_menu li a.cart-menu-btn strong {
  font-size: 11px;
  font-size: 0.6875rem;
  width: 15px;
  height: 15px;
  display: block;
  background-color: #333;
  color: #fff;
  position: relative;
  bottom: -15px;
  right: -10px;
  text-align: center;
  line-height: 17px !important;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  margin: 0;
  padding: 0;
}
ul#top_menu li a.logged {
  display: block;
  width: 25px;
  height: 25px;
  position: relative;
  overflow: hidden;
  top: 6px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  -moz-transition: opacity 0.5s ease;
  -o-transition: opacity 0.5s ease;
  -webkit-transition: opacity 0.5s ease;
  -ms-transition: opacity 0.5s ease;
  transition: opacity 0.5s ease;
}
ul#top_menu li a.logged img {
  width: 26px;
  height: auto;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
@media (max-width: 991px) {
  ul#top_menu li a.logged {
    top: 0;
  }
}

/* Dropdown user logged */
.dropdown-user .dropdown-menu {
  border: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  border-radius: 0;
  min-width: 0;
  padding: 0;
  width: 150px;
  top: 160%;
  margin-left: -115px;
  font-weight: normal;
  text-shadow: none;
  text-transform: none !important;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.02);
  border-left: 1px solid rgba(0, 0, 0, 0.02);
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  -webkit-box-shadow: 3px 2px 7px 1px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 3px 2px 7px 1px rgba(0, 0, 0, 0.08);
  box-shadow: 3px 2px 7px 1px rgba(0, 0, 0, 0.08);
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  padding: 0;
}
.dropdown-user .dropdown-menu:before {
  bottom: 100%;
  right: 10%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-bottom-color: #fff;
  border-width: 7px;
  margin-left: -7px;
}
.dropdown-user .dropdown-menu ul {
  padding: 0;
  margin: 0 !important;
  list-style: none;
}
.dropdown-user .dropdown-menu ul li {
  border-bottom: 1px solid #ededed;
  margin: 0;
  width: 100%;
  margin-bottom: 15px;
  display: inline-block;
  font-size: 13px;
  font-size: 0.8125rem;
}
@media (max-width: 991px) {
  .dropdown-user .dropdown-menu ul li {
    margin: 0 !important;
  }
}
.dropdown-user .dropdown-menu ul li a {
  color: #444 !important;
  padding: 15px 8px 15px 8px;
  display: block;
}
.dropdown-user .dropdown-menu ul li a:hover {
  color: #fc5b62 !important;
  opacity: 1 !important;
  padding-left: 15px;
}
.dropdown-user .dropdown-menu ul li:last-child {
  border-bottom: none;
  margin-bottom: 0;
}
@media (max-width: 991px) {
  .dropdown-user .dropdown-menu ul li:first-child {
    display: block !important;
  }
}

header.sticky .hamburger-inner, header.sticky .hamburger-inner::before, header.sticky .hamburger-inner::after {
  background-color: #444;
}

#logo {
  float: left;
}
@media (max-width: 991px) {
  #logo {
    float: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    text-align: center;
  }
  #logo img {
    width: auto;
    height: 30px;
    margin: 12px 0 0 0;
  }
}

.hero_in {
  width: 100%;
  height: 450px;
  position: relative;
  overflow: hidden;
  text-align: center;
  color: #fff;
}
@media (max-width: 767px) {
  .hero_in {
    height: 350px;
  }
}
.hero_in.general:before {
  background: url(../img/hero_in_bg_2.jpg) center center no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.hero_in.general .wrapper {
  background-color: black;
  background-color: rgba(0, 0, 0, 0.6);
}
.hero_in.tours:before {
  background: url(../img/hero_in_bg_1.jpg) center center no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.hero_in.tours .wrapper {
  background-color: black;
  background-color: rgba(0, 0, 0, 0.6);
}
.hero_in.hotels:before {
  background: url(../img/hero_in_bg_4.jpg) center center no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.hero_in.hotels .wrapper {
  background-color: black;
  background-color: rgba(0, 0, 0, 0.6);
}
.hero_in.restaurants:before {
  background: url(../img/hero_in_bg_5.jpg) center center no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.hero_in.restaurants .wrapper {
  background-color: black;
  background-color: rgba(0, 0, 0, 0.6);
}
.hero_in.tours_detail {
  height: 550px;
}
@media (max-width: 575px) {
  .hero_in.tours_detail {
    height: 500px;
  }
}
.hero_in.tours_detail:before {
  background: url(../img/hero_in_tours_detail.jpg) center center no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.hero_in.tours_detail .wrapper {
  background-color: black;
  background-color: rgba(0, 0, 0, 0.6);
}
.hero_in.hotels_detail {
  height: 550px;
}
@media (max-width: 575px) {
  .hero_in.hotels_detail {
    height: 500px;
  }
}
/* .hero_in.hotels_detail:before {
  background: url(../img/hero_in_hotels_detail.jpg) center center no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
} */
.hero_in.hotels_detail .wrapper {
  background-color: black;
  background-color: rgba(0, 0, 0, 0.6);
}
.hero_in.restaurants_detail {
  height: 550px;
}
@media (max-width: 575px) {
  .hero_in.restaurants_detail {
    height: 500px;
  }
}
.hero_in.restaurants_detail:before {
  background: url(../img/hero_in_restaurants_detail.jpg) center center no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.hero_in.restaurants_detail .wrapper {
  background-color: black;
  background-color: rgba(0, 0, 0, 0.6);
}
.hero_in.contacts:before {
  background: url(../img/hero_in_bg_3.jpg) center center no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.hero_in.contacts .wrapper {
  background-color: black;
  background-color: rgba(0, 0, 0, 0.6);
}
.hero_in:before {
  animation: pop-in 5s 0.3s cubic-bezier(0, 0.5, 0, 1) forwards;
  content: "";
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
}
.hero_in .wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.hero_in .wrapper h1 {
  color: #fff;
  font-size: 42px;
  font-size: 2.625rem;
  text-transform: uppercase;
  font-weight: 700;
  margin-top: 30px;
}
@media (max-width: 767px) {
  .hero_in .wrapper h1 {
    font-size: 32px;
    font-size: 2rem;
  }
}
.hero_in .wrapper h1 span {
  width: 80px;
  height: 4px;
  margin: auto;
  background-color: #FFC107;
  display: block;
  margin: auto;
  margin-bottom: 20px;
  border-radius: 10px;
  opacity: 1;
}
.hero_in .wrapper p {
  font-weight: 300;
  margin-bottom: 0;
  font-size: 24px;
  font-size: 1.5rem;
}
.hero_in .wrapper p strong {
  font-weight: 600;
}
.hero_in a.btn_photos {
  position: absolute;
  left: 20px;
  bottom: 20px;
  background-color: #fff;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.2);
  line-height: 1;
  padding: 10px 15px;
  color: #444;
  font-weight: 500;
}
.hero_in a.btn_photos:hover {
  color: #fc5b62;
}

/* Animations */
@keyframes pop-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1);
  }
}
/* Delays */
.hero_in h1 {
  animation-delay: 0.8s;
  -webkit-animation-delay: 0.8s;
  -moz-animation-delay: 0.8s;
}

.hero_in p {
  animation-delay: 1.5s;
  -webkit-animation-delay: 1.5s;
  -moz-animation-delay: 1.5s;
  animation-duration: 0.3s;
  -webkit-animation-duration: 0.3s;
  -moz-animation-duration: 0.3s;
}

/* Main */
main {
  background-color: #f8f8f8;
  position: relative;
  z-index: 1;
}

/* Footer */
footer {
  background-color: #121921;
  color: #fbfbfb;
  color: rgba(255, 255, 255, 0.7);
}
footer h5 {
  color: #fff;
  margin: 25px 0;
  font-size: 18px;
  font-size: 1.125rem;
}
@media (max-width: 575px) {
  footer h5 {
    margin: 25px 0 10px 0;
  }
}
footer ul li {
  margin-bottom: 5px;
}
footer ul li a {
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  display: inline-block;
  position: relative;
  color: #fff;
  opacity: 0.7;
}
footer ul li a:hover {
  color: #fc5b62;
  opacity: 1;
}
footer ul li a i {
  margin-right: 10px;
  color: #fff;
}
footer ul.links li a:hover {
  -webkit-transform: translate(5px, 0);
  -moz-transform: translate(5px, 0);
  -ms-transform: translate(5px, 0);
  -o-transform: translate(5px, 0);
  transform: translate(5px, 0);
}
footer ul.links li a:hover:after {
  opacity: 1;
  color: #fc5b62;
}
footer ul.links li a:after {
  font-family: 'ElegantIcons';
  content: "\24";
  position: absolute;
  margin-left: 5px;
  top: 1px;
  opacity: 0;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
footer ul.contacts li a i {
  margin-right: 10px;
}
footer hr {
  opacity: 0.1;
}
footer #copy {
  text-align: right;
  font-size: 13px;
  font-size: 0.8125rem;
  opacity: 0.7;
}
@media (max-width: 767px) {
  footer #copy {
    text-align: left;
    margin: 5px 0 20px 0;
  }
}

/* Newsletter footer*/
#newsletter .form-group {
  position: relative;
}
#newsletter .loader {
  position: absolute;
  right: -20px;
  top: 11px;
  color: #fff;
  font-size: 12px;
  font-size: 0.75rem;
}
#newsletter h6 {
  color: #fff;
  margin: 15px 0 15px 0;
}
#newsletter .form-group {
  position: relative;
}
#newsletter input[type='email'] {
  border: 0;
  height: 40px;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  border-radius: 0;
  padding-left: 15px;
  font-size: 14px;
  font-size: 0.875rem;
  background-color: #f3f3f3;
  background-color: rgba(255, 255, 255, 0.06);
  color: #fff;
}
#newsletter input[type='email']:focus {
  border: 0;
  box-shadow: none;
}
#newsletter input {
  position: absolute;
  right: 0;
  color: #fff;
  font-size: 13px;
  font-size: 0.8125rem;
  font-weight: 600;
  top: 0;
  border: 0;
  padding: 0 12px;
  height: 40px;
  cursor: pointer;
  -webkit-border-radius: 0 3px 3px 0;
  -moz-border-radius: 0 3px 3px 0;
  -ms-border-radius: 0 3px 3px 0;
  border-radius: 0 3px 3px 0;
  background-color: #fc5b62;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  outline: none;
}
#newsletter input[type='submit']:hover {
  background-color: #FFC107;
  color: #222;
}

.follow_us {
  margin-top: 15px;
  animation-delay: 1.1s;
  -webkit-animation-delay: 1.1s;
  -moz-animation-delay: 1.1s;
}
.follow_us h5 {
  color: #fff;
}
.follow_us ul li {
  display: inline-block;
  margin-right: 10px;
  font-size: 20px;
  font-size: 1.25rem;
}
.follow_us ul li:first-child {
  color: #fff;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 2px;
  font-size: 13px;
  font-size: 0.8125rem;
}
.follow_us ul li a {
  color: #fff;
  opacity: 0.7;
}
.follow_us ul li a:hover {
  opacity: 1;
}

ul#footer-selector {
  margin: 0 0 0 0;
  padding: 0;
  list-style: none;
}
ul#footer-selector li {
  float: left;
  margin-right: 10px;
}
@media (max-width: 575px) {
  ul#footer-selector li:last-child {
    margin-top: 5px;
  }
}

/* Addtional links Footer */
ul#additional_links {
  margin: 0;
  padding: 8px 0 0 0;
  color: #555;
  font-size: 13px;
  font-size: 0.8125rem;
  float: right;
}
@media (max-width: 991px) {
  ul#additional_links {
    float: none;
    margin-top: 10px;
  }
}
ul#additional_links li {
  display: inline-block;
  margin-right: 15px;
}
ul#additional_links li:first-child {
  margin-right: 20px;
}
ul#additional_links li:last-child:after {
  content: "";
}
ul#additional_links li span {
  color: #fff;
  opacity: 0.5;
}
ul#additional_links li a {
  color: #fff;
  opacity: 0.5;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
ul#additional_links li a:hover {
  opacity: 1;
}
ul#additional_links li:after {
  content: "|";
  font-weight: 300;
  position: relative;
  left: 10px;
}

.styled-select {
  width: 100%;
  overflow: hidden;
  height: 30px;
  line-height: 30px;
  border: none;
  background-color: #f3f3f3;
  background-color: rgba(255, 255, 255, 0.05);
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  padding-right: 35px;
  position: relative;
}
.styled-select#lang-selector:after {
  font-family: 'ElegantIcons';
  content: "\e0e2";
  color: #fff;
  position: absolute;
  right: 10px;
  top: 0;
}
.styled-select#currency-selector:after {
  font-family: 'ElegantIcons';
  content: "\33";
  color: #fff;
  position: absolute;
  right: 10px;
  top: 0;
}
.styled-select select {
  background: transparent;
  width: 110%;
  padding-left: 10px;
  border: 0;
  border-radius: 0;
  box-shadow: none;
  height: 30px;
  color: #ccc;
  font-size: 12px;
  font-size: 0.75rem;
  margin: 0;
  font-weight: 500;
  -moz-appearance: window;
  -webkit-appearance: none;
  cursor: pointer;
  outline: none;
}
.styled-select select:focus {
  color: #fff;
  outline: none;
  box-shadow: none;
}
.styled-select select::-ms-expand {
  display: none;
}

.styled-select#lang-selector select option {
  color: #555;
}

/* Wow on scroll event */
.wow {
  visibility: hidden;
}

/*============================================================================================*/
/* 2.  CONTENT */
/*============================================================================================*/
/*-------- 2.1 Home/front page--------*/
/* ------ jQuery FlexSlider v2.7.0 ----------- */
/* Resets */
.flex-container a:hover,
.flex-slider a:hover {
  outline: none;
}

.slides,
.slides > li,
.flex-control-nav,
.flex-direction-nav {
  margin: 0;
  padding: 0;
  list-style: none;
}

.flex-pauseplay span {
  text-transform: capitalize;
}

/* Base style */
.flexslider {
  margin: 0;
  padding: 0;
}

.flexslider .slides > li {
  display: none;
  -webkit-backface-visibility: hidden;
}

.flexslider .slides img {
  width: 100%;
  display: block;
}

.flexslider .slides:after {
  content: "\0020";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

html[xmlns] .flexslider .slides {
  display: block;
}

* html .flexslider .slides {
  height: 1%;
}

.no-js .flexslider .slides > li:first-child {
  display: block;
}

/* Default theme */
.flexslider {
  margin: 0;
  background: #fff;
  position: relative;
  zoom: 1;
}

.flexslider .slides {
  zoom: 1;
}

.flexslider .slides img {
  height: auto;
  -moz-user-select: none;
}

.flex-viewport {
  max-height: 2000px;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -ms-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}

.loading .flex-viewport {
  max-height: 300px;
}

.carousel li {
  margin-right: 5px;
}

.flex-direction-nav {
  position: absolute;
  bottom: 50px;
  height: 30px;
  line-height: 1;
  right: 0;
  bottom: 0;
  width: 100%;
  z-index: 9999;
}

.flex-direction-nav a {
  text-decoration: none;
  display: block !important;
  width: 45px;
  height: 52px;
  position: absolute;
  top: -65px;
  z-index: 10;
  overflow: hidden;
  opacity: 0;
  cursor: pointer;
  color: rgba(255, 255, 255, 0.8);
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.flex-direction-nav a:before {
  font-family: "ElegantIcons";
  font-size: 46px;
  display: inline-block;
  content: '\34';
  color: white;
}

.flex-direction-nav a.flex-next:before {
  content: '\35';
}

.flex-direction-nav .flex-next {
  text-align: right;
}

#carousel_slider .flex-direction-nav .flex-prev {
  opacity: 0.8;
  left: 15px;
}

#carousel_slider .flex-direction-nav .flex-prev:hover {
  opacity: 1;
}

#carousel_slider .flex-direction-nav .flex-next {
  opacity: 0.8;
  right: 15px;
}

#carousel_slider .flex-direction-nav .flex-next:hover {
  opacity: 1;
}

#carousel_slider .flex-direction-nav .flex-disabled {
  opacity: 0 !important;
  filter: alpha(opacity=0);
  cursor: default;
  z-index: -1;
}

.flex-control-nav {
  width: 100%;
  position: absolute;
  bottom: -40px;
  text-align: center;
}

.flex-control-nav li {
  margin: 0 6px;
  display: inline-block;
  zoom: 1;
  *display: inline;
}

/* Custom theme */
#slider.flexslider li {
  position: relative;
}

#slider.flexslider li .meta {
  position: absolute;
  bottom: 35%;
  left: 60px;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  font-weight: 500;
  z-index: 99;
  padding-right: 45px;
}
@media (max-width: 767px) {
  #slider.flexslider li .meta {
    left: 45px;
    bottom: auto;
    top: 50%;
  }
}

#slider.flexslider li h3 {
  font-size: 36px;
  font-size: 2.25rem;
  color: #fff;
}
@media (max-width: 767px) {
  #slider.flexslider li h3 {
    font-size: 24px;
    font-size: 1.5rem;
  }
}

#slider.flexslider li .info {
  display: flex;
  flex-direction: row;
}

#slider.flexslider li .info p {
  margin-bottom: 15px;
  font-size: 16px;
  font-size: 1rem;
}
@media (max-width: 767px) {
  #slider.flexslider li .info p {
    font-size: 14px;
    font-size: 0.875rem;
  }
}

#slider.flexslider li h3,
#slider.flexslider li .info p {
  -webkit-animation-duration: .6s;
  animation-duration: .6s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}

#slider.flexslider li .meta a {
  -webkit-animation-duration: .3s;
  animation-duration: .3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: zoomOut;
  animation-name: zoomOut;
  padding: 12px 20px;
}

#slider.flexslider li.flex-active-slide .meta h3,
#slider.flexslider li.flex-active-slide .meta .info p {
  -webkit-animation-delay: .4s;
  animation-delay: .4s;
  -webkit-animation-duration: .6s;
  animation-duration: .6s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

#slider.flexslider li.flex-active-slide .meta a {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
  -webkit-animation-duration: .3s;
  animation-duration: .3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: zoomIn;
  animation-name: zoomIn;
}

#slider.flexslider li.flex-active-slide .meta .info p {
  -webkit-animation-delay: .6s;
  animation-delay: .6s;
}

.slider {
  position: relative;
}

#slider.flexslider {
  overflow: hidden;
}
#slider.flexslider .flex-direction-nav {
  opacity: 0;
  display: none;
}
#slider.flexslider ul.slides li {
  height: 650px;
  background-color: #000;
}
@media (max-width: 1199px) {
  #slider.flexslider ul.slides li {
    height: 650px !important;
  }
}
@media (max-width: 767px) {
  #slider.flexslider ul.slides li {
    height: 500px !important;
  }
}
#slider.flexslider ul.slides li img {
  opacity: 0.5;
  width: 100%;
  height: auto;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
@media (max-width: 1199px) {
  #slider.flexslider ul.slides li img {
    height: 650px !important;
    width: auto;
  }
}
@media (max-width: 767px) {
  #slider.flexslider ul.slides li img {
    height: 500px !important;
    width: auto;
  }
}

#icon_drag_mobile {
  position: absolute;
  right: 20px;
  bottom: 20px;
  background: url(../img/drag_icon.svg) 0 0 no-repeat;
  width: 50px;
  height: 30px;
  opacity: 0.6;
  z-index: 99;
  display: none;
}
@media (max-width: 767px) {
  #icon_drag_mobile {
    display: block;
  }
}

#carousel_slider_wp {
  background: transparent;
  background: -webkit-linear-gradient(top, transparent, #000);
  background: linear-gradient(to bottom, transparent, #000);
  position: absolute;
  bottom: 0;
  z-index: 9;
  width: 100%;
  padding: 0 60px 45px 60px;
}
@media (max-width: 767px) {
  #carousel_slider_wp {
    display: none;
  }
}

#carousel_slider.flexslider {
  overflow: hidden;
  background-color: transparent;
}
#carousel_slider.flexslider ul.slides {
  padding-top: 20px;
}
#carousel_slider.flexslider ul.slides li {
  position: relative;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  background-color: #000;
  -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.5);
  overflow: hidden;
  cursor: pointer;
}
#carousel_slider.flexslider ul.slides li:hover img {
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
  transform: scale(1.2);
}
#carousel_slider.flexslider ul.slides li img {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  opacity: 0.4;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
#carousel_slider.flexslider ul.slides li .caption {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 9;
  width: 100%;
  height: 100%;
  padding: 20px;
  color: #fff;
}
#carousel_slider.flexslider ul.slides li .caption h3 {
  font-size: 21px;
  font-size: 1.3125rem;
  color: #fff;
  font-weight: 500;
}
#carousel_slider.flexslider ul.slides li .caption h3 span {
  margin: 3px 0 0 0;
  display: block;
  text-transform: uppercase;
  font-size: 12px;
  font-size: 0.75rem;
}
#carousel_slider.flexslider ul.slides li .caption small {
  opacity: 0;
  -webkit-transition: all 0.3s cubic-bezier(0.77, 0, 0.175, 1);
  -moz-transition: all 0.3s cubic-bezier(0.77, 0, 0.175, 1);
  -ms-transition: all 0.3s cubic-bezier(0.77, 0, 0.175, 1);
  -o-transition: all 0.3s cubic-bezier(0.77, 0, 0.175, 1);
  transition: all 0.3s cubic-bezier(0.77, 0, 0.175, 1);
  position: absolute;
  bottom: 10px;
  left: 20px;
  display: block;
  width: 100%;
  font-weight: 500;
  font-size: 14px;
  font-size: 0.875rem;
  -webkit-transform: translate(0, 10px);
  -moz-transform: translate(0, 10px);
  -ms-transform: translate(0, 10px);
  -o-transform: translate(0, 10px);
  transform: translate(0, 10px);
}
#carousel_slider.flexslider ul.slides li .caption small strong {
  float: right;
  display: inline-block;
  text-align: right;
  margin: 0 40px 0 0;
  padding: 3px 0 0 0;
}
#carousel_slider.flexslider ul.slides li .caption small strong h6 {
  color: #fff;
  margin: 0;
  font-weight: 500;
  font-size: 14px;
  font-size: 0.875rem;
}
#carousel_slider.flexslider ul.slides li:hover .caption small {
  opacity: 1;
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
}

a#moveDown {
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 9999;
  font-size: 32px;
  font-size: 2rem;
  color: #fff;
  animation-duration: 6s;
  -webkit-animation-duration: 6s;
  -moz-animation-duration: 6s;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
}

/* Home video background*/
.header-video {
  position: relative;
  overflow: hidden;
  background: #000;
  height: 600px !important;
}
@media (max-width: 991px) {
  .header-video {
    height: 620px !important;
  }
}
.header-video video {
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.8;
}
.header-video iframe {
  height: 100%;
  width: 100%;
}

#hero_video {
  position: relative;
  background-size: cover;
  color: #fff;
  width: 100%;
  font-size: 16px;
  font-size: 1rem;
  height: 100%;
  z-index: 99;
  text-align: center;
  display: table;
}
#hero_video .wrapper {
  display: table-cell;
  vertical-align: middle;
}
#hero_video .wrapper h3 {
  color: #fff;
  font-size: 42px;
  font-size: 2.625rem;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 0;
  text-shadow: 4px 4px 12px rgba(0, 0, 0, 0.3);
}
@media (max-width: 767px) {
  #hero_video .wrapper h3 {
    font-size: 26px;
    font-size: 1.625rem;
    margin-top: 60px;
  }
}
@media (max-width: 575px) {
  #hero_video .wrapper h3 {
    font-size: 22px;
    font-size: 1.375rem;
  }
}
#hero_video .wrapper p {
  font-weight: 300;
  font-size: 24px;
  font-size: 1.5rem;
  line-height: 1.4;
  text-shadow: 4px 4px 12px rgba(0, 0, 0, 0.3);
}
#hero_video .wrapper p strong {
  font-weight: 600;
}
@media (max-width: 767px) {
  #hero_video .wrapper p {
    padding: 0;
    font-size: 14px;
    font-size: 0.875rem;
  }
}

.teaser-video {
  width: 100%;
  height: auto;
}

.header-video--media {
  width: 100%;
  height: auto;
}

@media (max-width: 1024px) {
  #hero_video {
    background: #ccc url(../img/home_section_1.jpg);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  #hero_video > div {
    background-color: black;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .header-video {
    background: none;
  }
}
/* Home single hero */
.hero_single {
  width: 100%;
  position: relative;
  overflow: hidden;
  text-align: center;
  margin: 0;
  color: #fff;
}
.hero_single .wrapper {
  background-color: black;
  background-color: rgba(0, 0, 0, 0.8);
}
.hero_single.start_bg_zoom:before {
  animation: pop-in 6s 0.3s cubic-bezier(0, 0.5, 0, 1) forwards;
  content: "";
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
}
.hero_single .wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.hero_single .wrapper h3 {
  color: #fff;
  font-size: 52px;
  font-size: 3.25rem;
  margin: 0;
  text-transform: uppercase;
  font-weight: 700;
}
@media (max-width: 767px) {
  .hero_single .wrapper h3 {
    font-size: 21px;
    font-size: 1.3125rem;
  }
}
@media (max-width: 575px) {
  .hero_single .wrapper h3 {
    font-size: 23px;
    font-size: 1.4375rem;
  }
}
.hero_single .wrapper p {
  font-weight: 300;
  margin: 10px 0 0 0;
  padding: 0 20%;
  font-size: 24px;
  font-size: 1.5rem;
  line-height: 1.4;
}
.hero_single .wrapper p strong {
  font-weight: 600;
}
@media (max-width: 767px) {
  .hero_single .wrapper p {
    padding: 0;
    font-size: 18px;
    font-size: 1.125rem;
  }
}
.hero_single.short {
  height: 600px;
}
.hero_single.version_2 {
  height: 620px;
}
.hero_single.version_2:before {
  background: url(../img/home_section_1.jpg) center center no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.hero_single.version_2 .wrapper {
  background-color: black;
  background-color: rgba(0, 0, 0, 0.45);
}
.hero_single.version_2 .wrapper h3 {
  font-size: 42px;
  font-size: 2.625rem;
  text-shadow: 4px 4px 12px rgba(0, 0, 0, 0.3);
}
@media (max-width: 767px) {
  .hero_single.version_2 .wrapper h3 {
    font-size: 26px;
    font-size: 1.625rem;
    margin-top: 60px;
  }
}
@media (max-width: 575px) {
  .hero_single.version_2 .wrapper h3 {
    font-size: 22px;
    font-size: 1.375rem;
  }
}
.hero_single.version_2 .wrapper p {
  font-weight: 400;
  margin: 5px 0 20px 0;
  padding: 0;
  font-size: 21px;
  font-size: 1.3125rem;
  text-shadow: 4px 4px 12px rgba(0, 0, 0, 0.3);
}
.hero_single.version_2 .wrapper p strong {
  font-weight: 600;
}
@media (max-width: 767px) {
  .hero_single.version_2 .wrapper p {
    padding: 0;
    font-size: 14px;
    font-size: 0.875rem;
  }
}

#custom-search-input {
  padding: 0;
  width: 600px;
  margin: 20px auto 0;
  position: relative;
}
@media (max-width: 991px) {
  #custom-search-input {
    width: auto;
  }
}
#custom-search-input .search-query {
  width: 100%;
  height: 50px;
  padding-left: 20px;
  border: 0;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  font-size: 16px;
  font-size: 1rem;
  color: #333;
}
#custom-search-input .search-query:focus {
  outline: none;
}
@media (max-width: 575px) {
  #custom-search-input .search-query {
    padding-left: 15px;
  }
}
#custom-search-input input[type='submit'] {
  position: absolute;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  right: -1px;
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  font-size: 0.875rem;
  top: 0;
  border: 0;
  padding: 0 25px;
  height: 50px;
  cursor: pointer;
  outline: none;
  -webkit-border-radius: 0 3px 3px 0;
  -moz-border-radius: 0 3px 3px 0;
  -ms-border-radius: 0 3px 3px 0;
  border-radius: 0 3px 3px 0;
  background-color: #fc5b62;
}
#custom-search-input input[type='submit']:hover {
  background-color: #FFC107;
  color: #222;
}
@media (max-width: 575px) {
  #custom-search-input input[type='submit'] {
    text-indent: -999px;
    background: #fc5b62 url(../img/search.svg) no-repeat center center;
  }
}

.custom-search-input-2 {
  background-color: #fff;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  margin-top: 15px;
  -webkit-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.3);
}
@media (max-width: 991px) {
  .custom-search-input-2 {
    background: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
}
.custom-search-input-2 input {
  border: 0;
  height: 50px;
  padding-left: 15px;
  border-right: 1px solid #d2d8dd;
  font-weight: 500;
}
@media (max-width: 991px) {
  .custom-search-input-2 input {
    border: none;
  }
}
.custom-search-input-2 input:focus {
  box-shadow: none;
  border-right: 1px solid #d2d8dd;
}
@media (max-width: 991px) {
  .custom-search-input-2 input:focus {
    border-right: none;
  }
}
.custom-search-input-2 select {
  display: none;
}
.custom-search-input-2 .nice-select .current {
  font-weight: 500;
  color: #6f787f;
}
.custom-search-input-2 .form-group {
  margin: 0;
}
@media (max-width: 991px) {
  .custom-search-input-2 .form-group {
    margin-bottom: 5px;
  }
}
.custom-search-input-2 i {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  font-size: 18px;
  font-size: 1.125rem;
  position: absolute;
  background-color: #fff;
  line-height: 50px;
  top: 0;
  right: 1px;
  padding-right: 15px;
  display: block;
  width: 20px;
  box-sizing: content-box;
  height: 50px;
  z-index: 9;
  color: #999;
}
@media (max-width: 991px) {
  .custom-search-input-2 i {
    padding-right: 10px;
  }
}
.custom-search-input-2 input[type='submit'],
.custom-search-input-2 input[type='button'] {
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  font-size: 0.875rem;
  border: 0;
  padding: 0 25px;
  height: 50px;
  cursor: pointer;
  outline: none;
  width: 100%;
  -webkit-border-radius: 0 3px 3px 0;
  -moz-border-radius: 0 3px 3px 0;
  -ms-border-radius: 0 3px 3px 0;
  border-radius: 0 3px 3px 0;
  background-color: #fc5b62;
  margin-right: -1px;
}
@media (max-width: 991px) {
  .custom-search-input-2 input[type='submit'],
  .custom-search-input-2 input[type='button'] {
    margin: 20px 0 0 0;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
  }
}
.custom-search-input-2 input[type='submit']:hover,
.custom-search-input-2 input[type='button']:hover {
  background-color: #FFC107;
  color: #222;
}
.custom-search-input-2.inner {
  margin-bottom: 30px;
  -webkit-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
}
@media (max-width: 991px) {
  .custom-search-input-2.inner {
    margin: 0 0 20px 0;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
}
.custom-search-input-2.inner-2 {
  margin: 0 0 20px 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  background: none;
}
.custom-search-input-2.inner-2 .form-group {
  margin-bottom: 10px;
}
.custom-search-input-2.inner-2 input {
  border: 1px solid #ededed;
}
.custom-search-input-2.inner-2 input[type='submit'] {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  margin-top: 10px;
}
.custom-search-input-2.inner-2 i {
  padding-right: 10px;
  line-height: 48px;
  height: 48px;
  top: 1px;
}
.custom-search-input-2.inner-2 .nice-select {
  border: 1px solid #ededed;
}

/* Panel Drop Down */
.panel-dropdown {
  position: relative;
  text-align: left;
  padding: 15px 10px 0 15px;
}
@media (max-width: 991px) {
  .panel-dropdown {
    background-color: #fff;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
    height: 50px;
  }
}
.panel-dropdown a {
  color: #727b82;
  font-weight: 500;
  transition: all 0.3s;
  display: block;
  position: relative;
}
.panel-dropdown a:after {
  font-family: 'ElegantIcons';
  content: "\33";
  font-size: 24px;
  font-size: 1.5rem;
  color: #999;
  font-weight: 500;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  position: absolute;
  right: 0;
  top: -8px;
}
.panel-dropdown.active a:after {
  transform: rotate(180deg);
}
.panel-dropdown .panel-dropdown-content {
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
  position: absolute;
  top: 58px;
  left: 0px;
  z-index: 99;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  -webkit-box-shadow: 0 12px 35px 2px rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0 12px 35px 2px rgba(0, 0, 0, 0.12);
  box-shadow: 0 12px 35px 2px rgba(0, 0, 0, 0.12);
  padding: 15px 15px 0 15px;
  white-space: normal;
  width: 280px;
}
.panel-dropdown .panel-dropdown-content:before, .panel-dropdown .panel-dropdown-content:after {
  position: absolute;
  display: inline-block;
  border-bottom-color: rgba(0, 0, 0, 0.2);
  content: '';
}
.panel-dropdown .panel-dropdown-content:before {
  top: -7px;
  left: 9px;
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
  border-bottom: 7px solid #ccc;
}
.panel-dropdown .panel-dropdown-content:after {
  left: 10px;
  top: -6px;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #fff;
  border-left: 6px solid transparent;
}
.panel-dropdown .panel-dropdown-content.right {
  left: auto;
  right: 0;
}
.panel-dropdown .panel-dropdown-content.right:before {
  left: auto;
  right: 9px;
}
.panel-dropdown .panel-dropdown-content.right:after {
  left: auto;
  right: 10px;
}

.panel-dropdown.active .panel-dropdown-content {
  opacity: 1;
  visibility: visible;
}

.qtyButtons {
  display: flex;
  margin: 0 0 13px 0;
}
.qtyButtons input {
  outline: 0;
  font-size: 16px;
  font-size: 1rem;
  text-align: center;
  width: 50px;
  height: 36px !important;
  color: #333;
  line-height: 36px;
  margin: 0 !important;
  padding: 0 5px !important;
  border: none;
  box-shadow: none;
  pointer-events: none;
  display: inline-block;
  border: none !important;
}
.qtyButtons label {
  font-weight: 500;
  line-height: 36px;
  padding-right: 15px;
  display: block;
  flex: 1;
  color: #727b82;
}

.qtyInc,
.qtyDec {
  width: 36px;
  height: 36px;
  line-height: 36px;
  font-size: 28px;
  font-size: 1.75rem;
  background-color: #f2f2f2;
  -webkit-text-stroke: 1px #f2f2f2;
  color: #333;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  cursor: pointer;
  font-family: 'ElegantIcons';
}

.qtyInc:before {
  content: "\4c";
}

.qtyDec:before {
  content: "\4b";
}

.qtyTotal {
  background-color: #66676b;
  border-radius: 50%;
  color: #fff;
  display: inline-block;
  font-size: 11px;
  font-weight: 600;
  font-family: "Open Sans", sans-serif;
  line-height: 18px;
  text-align: center;
  position: relative;
  top: 0;
  left: 2px;
  height: 18px;
  width: 18px;
}

.rotate-x {
  animation-duration: .5s;
  animation-name: rotate-x;
}

@keyframes rotate-x {
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(360deg);
  }
}
/*Carousel home page*/
#reccomended {
  margin-top: 40px;
}
@media (max-width: 767px) {
  #reccomended {
    margin-top: 0;
  }
}
#reccomended .item {
  margin: 0 15px;
}

#reccomended .owl-item {
  opacity: 0.5;
  transform: scale(0.85);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translateZ(0) scale(0.85, 0.85);
  transition: all 0.3s ease-in-out 0s;
  overflow: hidden;
}

#reccomended .owl-item.active.center {
  opacity: 1;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translateZ(0) scale(1, 1);
  transform: scale(1);
}

#reccomended .owl-item.active.center .item .title h4,
#reccomended .owl-item.active.center .item .views {
  opacity: 1;
}

.owl-theme .owl-dots {
  margin-top: 10px !important;
  margin-bottom: 25px;
}
@media (max-width: 767px) {
  .owl-theme .owl-dots {
    margin-top: 0 !important;
    margin-bottom: 20px;
  }
}

.owl-theme .owl-dots .owl-dot {
  outline: none;
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background: #fc5b62 !important;
}

.owl-theme .owl-dots .owl-dot span {
  width: 13px !important;
  height: 13px !important;
}

a.box_news {
  position: relative;
  display: block;
  padding-left: 230px;
  color: #555;
  margin-bottom: 30px;
  min-height: 150px;
}
@media (max-width: 767px) {
  a.box_news {
    min-height: inherit;
    padding-left: 0;
  }
}
a.box_news figure {
  width: 200px;
  height: 150px;
  overflow: hidden;
  position: absolute;
  left: 0;
  top: 0;
}
@media (max-width: 767px) {
  a.box_news figure {
    position: relative;
    width: auto;
  }
}
a.box_news figure img {
  width: 250px;
  height: auto;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%) scale(1.1);
  -moz-transform: translate(-50%, -50%) scale(1.1);
  -ms-transform: translate(-50%, -50%) scale(1.1);
  -o-transform: translate(-50%, -50%) scale(1.1);
  transform: translate(-50%, -50%) scale(1.1);
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
}
@media (max-width: 767px) {
  a.box_news figure img {
    width: 100%;
    max-width: 100%;
    height: auto;
  }
}
a.box_news figure figcaption {
  background-color: #FFC107;
  text-align: center;
  position: absolute;
  right: 0;
  top: 0;
  display: inline-block;
  color: #fff;
  width: 60px;
  height: 60px;
  padding: 15px;
  line-height: 1;
}
a.box_news figure figcaption strong {
  display: block;
  font-size: 21px;
  font-size: 1.3125rem;
}
a.box_news h4 {
  font-size: 18px;
  font-size: 1.125rem;
  margin-bottom: 10px;
}
@media (max-width: 767px) {
  a.box_news h4 {
    margin-bottom: 5px;
  }
}
a.box_news:hover img {
  -webkit-transform: translate(-50%, -50%) scale(1);
  -moz-transform: translate(-50%, -50%) scale(1);
  -ms-transform: translate(-50%, -50%) scale(1);
  -o-transform: translate(-50%, -50%) scale(1);
  transform: translate(-50%, -50%) scale(1);
}
a.box_news:hover h4 {
  color: #fc5b62;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
a.box_news ul {
  margin: 0;
  padding: 10px 0 0 0;
}
@media (max-width: 767px) {
  a.box_news ul {
    padding: 0;
  }
}
a.box_news ul li {
  display: inline-block;
  font-weight: 600;
  text-transform: uppercase;
  color: #999;
  font-size: 12px;
  font-size: 0.75rem;
  margin-right: 5px;
}
a.box_news ul li:last-child {
  margin-right: 0;
}
a.box_news ul li:last-child:after {
  content: '';
  margin: 0;
}
a.box_news ul li:after {
  content: '-';
  margin: 0 0 0 10px;
}

a.grid_item {
  display: block;
  margin-bottom: 25px;
}
a.grid_item .info {
  position: absolute;
  width: 100%;
  z-index: 9;
  display: block;
  padding: 25px 20px 5px 20px;
  color: #fff;
  left: 0;
  bottom: 0;
  background: transparent;
  background: -webkit-linear-gradient(top, transparent, #000);
  background: linear-gradient(to bottom, transparent, #000);
  box-sizing: border-box;
}
a.grid_item .info .cat_star {
  font-size: 9px;
  font-size: 0.5625rem;
}
a.grid_item .info .cat_star i {
  color: #fff;
}
a.grid_item .info h3 {
  color: #fff;
  font-size: 18px;
  font-size: 1.125rem;
}
a.grid_item .info p {
  color: #fff;
  margin-bottom: 0;
  font-size: 15px;
  font-size: 0.9375rem;
}
a.grid_item figure {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
}
@media (max-width: 767px) {
  a.grid_item figure {
    height: 350px !important;
  }
}
@media (max-width: 575px) {
  a.grid_item figure {
    height: 220px !important;
  }
}
a.grid_item figure .score {
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 9;
}
a.grid_item figure small {
  position: absolute;
  background-color: black;
  background-color: rgba(0, 0, 0, 0.6);
  left: 20px;
  top: 22px;
  text-transform: uppercase;
  color: #ccc;
  font-weight: 600;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  padding: 7px 10px 4px 10px;
  line-height: 1;
  z-index: 9;
}
a.grid_item figure img {
  width: 100%;
  height: auto;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
  transform: scale(1.1);
}
a.grid_item:hover figure img {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}

.search_container {
  padding: 30px 0 15px 0;
  border-bottom: 1px solid #ededed;
}

/* ------ Search 3 index-10.html ----------- */
.custom-search-input-2.version_3 {
  margin-top: 20px;
}
.custom-search-input-2.version_3 .form-group {
  text-align: left;
}
.custom-search-input-2.version_3 .form-group span {
  color: #111;
  position: absolute;
  left: 15px;
  top: 12px;
  font-size: 12px;
  font-size: 0.75rem;
  font-weight: 500;
}
.custom-search-input-2.version_3 .form-group input {
  height: 58px;
  padding: 22px 10px 5px 15px;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  border-radius: 0;
  background-color: transparent;
}
@media (max-width: 991px) {
  .custom-search-input-2.version_3 .form-group input {
    background-color: #fff;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
  }
}
.custom-search-input-2.version_3 .form-group input:hover {
  background-color: black;
  background-color: rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  border-radius: 0;
}
@media (max-width: 991px) {
  .custom-search-input-2.version_3 .form-group input:hover {
    background-color: white;
    background-color: white;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
  }
}
.custom-search-input-2.version_3 .form-group input:focus {
  background-color: black;
  background-color: transparent;
}
@media (max-width: 991px) {
  .custom-search-input-2.version_3 .form-group input:focus {
    background-color: white;
    background-color: white;
  }
}
@media (max-width: 991px) {
  .custom-search-input-2.version_3 .panel-dropdown {
    height: 58px;
  }
}
.custom-search-input-2.version_3 .panel-dropdown a {
  height: 43px;
  padding: 13px 10px 0px 0;
  display: block;
}
.custom-search-input-2.version_3 .panel-dropdown a:after {
  display: none !important;
}
.custom-search-input-2.version_3 .panel-dropdown a span {
  position: relative;
  top: 0;
  left: 0;
  color: #fff;
}
.custom-search-input-2.version_3 .panel-dropdown:hover {
  background-color: black;
  background-color: rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  border-radius: 0;
}
@media (max-width: 991px) {
  .custom-search-input-2.version_3 .panel-dropdown:hover {
    background-color: white;
    background-color: white;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
  }
}
.custom-search-input-2.version_3 .panel-dropdown.active {
  background-color: black;
  background-color: transparent;
}
@media (max-width: 991px) {
  .custom-search-input-2.version_3 .panel-dropdown.active {
    background-color: white;
    background-color: white;
  }
}
.custom-search-input-2.version_3 .panel-dropdown .panel-dropdown-content {
  top: 70px;
}
.custom-search-input-2.version_3 input[type='submit'] {
  height: 58px;
}

/*-------- 2.2 About --------*/
a.box_feat {
  text-align: center;
  background: #fff;
  padding: 40px 30px 30px 30px;
  display: block;
  -moz-transition: all 500ms ease-in-out;
  -o-transition: all 500ms ease-in-out;
  -webkit-transition: all 500ms ease-in-out;
  -ms-transition: all 500ms ease-in-out;
  transition: all 500ms ease-in-out;
  -webkit-box-shadow: 0px 3px 0px 0px #f0f2f4;
  -moz-box-shadow: 0px 3px 0px 0px #f0f2f4;
  box-shadow: 0px 3px 0px 0px #f0f2f4;
  color: #555;
  margin-bottom: 30px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  overflow: hidden;
}
@media (max-width: 767px) {
  a.box_feat {
    padding: 20px 20px 0 20px;
  }
}
a.box_feat:hover {
  -webkit-transform: translateY(-10px);
  -moz-transform: translateY(-10px);
  -ms-transform: translateY(-10px);
  -o-transform: translateY(-10px);
  transform: translateY(-10px);
  -webkit-box-shadow: 0px 3px 0px 0px #ebedef;
  -moz-box-shadow: 0px 3px 0px 0px #ebedef;
  box-shadow: 0px 3px 0px 0px #ebedef;
}
a.box_feat i {
  font-size: 70px;
  font-size: 4.375rem;
  color: #32a067;
  margin-bottom: 10px;
}
a.box_feat h3 {
  font-size: 18px;
  font-size: 1.125rem;
}

#carousel {
  margin-top: 40px;
}
@media (max-width: 767px) {
  #carousel {
    margin-bottom: 30px;
  }
}
#carousel .item .title {
  position: absolute;
  bottom: 0;
  left: 0;
  text-align: center;
  width: 100%;
  z-index: 9;
}
#carousel .item .title h4 {
  background-color: #0054a6;
  background-color: #0054a6;
  display: inline-block;
  -webkit-border-radius: 3px 3px 0 0;
  -moz-border-radius: 3px 3px 0 0;
  -ms-border-radius: 3px 3px 0 0;
  border-radius: 3px 3px 0 0;
  color: #fff;
  padding: 10px 15px;
  margin: 0;
  font-size: 16px;
  font-size: 1rem;
  opacity: 0;
}
#carousel .item .title h4 em {
  display: block;
  font-size: 13px;
  font-size: 0.8125rem;
  font-weight: 300;
  margin-top: 3px;
}
#carousel .item a {
  position: relative;
  display: block;
}

#carousel .owl-item {
  opacity: 0.5;
  transform: scale(0.85);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translateZ(0) scale(0.85, 0.85);
  transition: all 0.3s ease-in-out 0s;
  overflow: hidden;
}

#carousel .owl-item.active.center {
  opacity: 1;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translateZ(0) scale(1, 1);
  transform: scale(1);
}

#carousel .owl-item.active.center .item .title h4,
#carousel .owl-item.active.center .item .views {
  opacity: 1;
}

/*-------- 2.3 Listing --------*/
.filters_listing {
  padding: 10px 0 5px 0;
  background: #fff;
  border-bottom: 1px solid #ededed;
  z-index: 999999 !important;
  position: relative;
}
.filters_listing ul {
  margin: 0 15px;
}
@media (max-width: 991px) {
  .filters_listing ul {
    margin: 0;
  }
}
.filters_listing ul li {
  margin-right: 10px;
}
.filters_listing ul li:first-child {
  float: left;
}
.filters_listing ul li:nth-child(2) {
  float: right;
  margin-right: 0;
}
.filters_listing ul li:nth-child(3) {
  float: right;
}

.score strong {
  background-color: #0054a6;
  color: #fff;
  line-height: 1;
  -webkit-border-radius: 5px 5px 5px 0;
  -moz-border-radius: 5px 5px 5px 0;
  -ms-border-radius: 5px 5px 5px 0;
  border-radius: 5px 5px 5px 0;
  padding: 10px;
  display: inline-block;
}
.score span {
  display: inline-block;
  position: relative;
  top: 7px;
  margin-right: 8px;
  font-size: 12px;
  font-size: 0.75rem;
  text-align: right;
  line-height: 1.1;
  font-weight: 500;
}
.score span em {
  display: block;
  font-weight: normal;
  font-size: 11px;
  font-size: 0.6875rem;
}

.wrapper-grid {
  padding: 0 20px;
}

.box_grid {
  background-color: #fff;
  display: block;
  position: relative;
  margin-bottom: 30px;
  -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
}
.box_grid .price {
  display: inline-block;
  font-weight: 500;
  color: #999;
}
.box_grid .price strong {
  color: #32a067;
}
.box_grid a.wish_bt {
  position: absolute;
  right: 15px;
  top: 15px;
  z-index: 1;
  background-color: black;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 9px 10px;
  display: inline-block;
  color: #fff;
  line-height: 1;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
}
.box_grid a.wish_bt:after {
  font-family: 'ElegantIcons';
  content: "\e030";
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.box_grid a.wish_bt.liked:after {
  content: "\e089";
  color: #fc5b62;
}
.box_grid a.wish_bt:hover.liked:after {
  color: #fc5b62;
}
.box_grid a.wish_bt:hover:after {
  content: "\e089";
  color: #fff;
}
.box_grid figure {
  margin-bottom: 0;
  overflow: hidden;
  position: relative;
  height: 210px;
}
.box_grid figure small {
  position: absolute;
  background-color: black;
  background-color: rgba(0, 0, 0, 0.6);
  left: 20px;
  top: 22px;
  text-transform: uppercase;
  color: #ccc;
  font-weight: 600;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  padding: 7px 10px 4px 10px;
  line-height: 1;
}
.box_grid figure .read_more {
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -12px;
  -webkit-transform: translateY(10px);
  -moz-transform: translateY(10px);
  -ms-transform: translateY(10px);
  -o-transform: translateY(10px);
  transform: translateY(10px);
  text-align: center;
  opacity: 0;
  visibility: hidden;
  width: 100%;
  -webkit-transition: all 0.6s;
  transition: all 0.6s;
  z-index: 2;
}
.box_grid figure .read_more span {
  background-color: #fcfcfc;
  background-color: rgba(255, 255, 255, 0.8);
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  border-radius: 20px;
  display: inline-block;
  color: #222;
  font-size: 12px;
  font-size: 0.75rem;
  padding: 5px 10px;
}
.box_grid figure:hover .read_more {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
}
.box_grid figure a img {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%) scale(1.1);
  -moz-transform: translate(-50%, -50%) scale(1.1);
  -ms-transform: translate(-50%, -50%) scale(1.1);
  -o-transform: translate(-50%, -50%) scale(1.1);
  transform: translate(-50%, -50%) scale(1.1);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
  width: 100%;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.box_grid figure a:hover img {
  -webkit-transform: translate(-50%, -50%) scale(1);
  -moz-transform: translate(-50%, -50%) scale(1);
  -ms-transform: translate(-50%, -50%) scale(1);
  -o-transform: translate(-50%, -50%) scale(1);
  transform: translate(-50%, -50%) scale(1);
}
.box_grid .wrapper {
  padding: 25px;
}
.box_grid .wrapper h3 {
  font-size: 20px;
  font-size: 1.25rem;
  margin-top: 0;
}
.box_grid ul {
  padding: 20px 15px;
  border-top: 1px solid #ededed;
}
.box_grid ul li {
  display: inline-block;
  margin-right: 15px;
}
.box_grid ul li .score {
  margin-top: -10px;
}
.box_grid ul li:last-child {
  margin-right: 0;
  float: right;
}

.cat_star i {
  margin-right: 2px;
  color: #FFC107;
}

.rating {
  color: #ccc;
}
.rating .voted {
  color: #FFC107;
}
.rating i {
  margin-right: 2px;
}

.box_list {
  background-color: #fff;
  display: block;
  position: relative;
  margin: 0 15px 30px 15px;
  min-height: 310px;
  -webkit-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
}
.box_list a.wish_bt {
  position: absolute;
  right: 15px;
  top: 15px;
  z-index: 1;
  background-color: black;
  background-color: rgba(0, 0, 0, 0.1);
  padding: 9px 10px;
  display: inline-block;
  color: #555;
  line-height: 1;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
}
.box_list a.wish_bt:after {
  font-family: 'ElegantIcons';
  content: "\e030";
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.box_list a.wish_bt.liked:after {
  content: "\e089";
  color: #fc5b62;
}
.box_list a.wish_bt:hover.liked:after {
  color: #fc5b62;
}
.box_list a.wish_bt:hover:after {
  content: "\e089";
  color: #999;
}
.box_list figure {
  margin-bottom: 0;
  overflow: hidden;
  position: relative;
  min-height: 310px;
}
@media (max-width: 991px) {
  .box_list figure {
    min-height: 220px;
    max-height: 220px;
    height: 220px;
  }
}
.box_list figure small {
  position: absolute;
  background-color: black;
  background-color: rgba(0, 0, 0, 0.6);
  right: 20px;
  top: 20px;
  text-transform: uppercase;
  color: #ccc;
  font-weight: 600;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  padding: 7px 10px 4px 10px;
  line-height: 1;
  z-index: 99;
}
@media (max-width: 991px) {
  .box_list figure small {
    right: 15px;
    top: 15px;
  }
}
@media (max-width: 991px) {
  .box_list figure {
    min-height: 220px;
    max-height: 220px;
    height: 220px;
  }
}
.box_list figure .read_more {
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -12px;
  -webkit-transform: translateY(10px);
  -moz-transform: translateY(10px);
  -ms-transform: translateY(10px);
  -o-transform: translateY(10px);
  transform: translateY(10px);
  text-align: center;
  opacity: 0;
  visibility: hidden;
  width: 100%;
  -webkit-transition: all 0.6s;
  transition: all 0.6s;
  z-index: 2;
}
.box_list figure .read_more span {
  background-color: #fcfcfc;
  background-color: rgba(255, 255, 255, 0.8);
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  border-radius: 20px;
  display: inline-block;
  color: #222;
  font-size: 12px;
  font-size: 0.75rem;
  padding: 5px 10px;
}
.box_list figure:hover .read_more {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
}
.box_list figure a img {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%) scale(1.1);
  -moz-transform: translate(-50%, -50%) scale(1.1);
  -ms-transform: translate(-50%, -50%) scale(1.1);
  -o-transform: translate(-50%, -50%) scale(1.1);
  transform: translate(-50%, -50%) scale(1.1);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
  width: 100%;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  height: 360px;
  width: auto;
  max-width: none;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
@media (max-width: 991px) {
  .box_list figure a img {
    max-width: inherit;
    height: 460px;
    width: auto;
  }
}
@media (max-width: 767px) {
  .box_list figure a img {
    height: 350px;
  }
}
.box_list figure a:hover img {
  -webkit-transform: translate(-50%, -50%) scale(1);
  -moz-transform: translate(-50%, -50%) scale(1);
  -ms-transform: translate(-50%, -50%) scale(1);
  -o-transform: translate(-50%, -50%) scale(1);
  transform: translate(-50%, -50%) scale(1);
}
.box_list .wrapper {
  padding: 30px 30px 20px 30px;
  min-height: 245px;
  position: relative;
}
@media (max-width: 991px) {
  .box_list .wrapper {
    min-height: inherit;
  }
}
.box_list .wrapper h3 {
  font-size: 20px;
  font-size: 1.25rem;
  margin-top: 0;
}
.box_list .wrapper .price {
  display: inline-block;
  font-weight: 500;
  color: #999;
}
.box_list .wrapper .price strong {
  color: #32a067;
}
.box_list small {
  text-transform: uppercase;
  color: #999;
  font-weight: 600;
}
.box_list ul {
  padding: 20px 15px 20px 30px;
  border-top: 1px solid #ededed;
  margin-bottom: 0;
}
.box_list ul li {
  display: inline-block;
  margin-right: 15px;
}
.box_list ul li .score {
  margin-top: -10px;
}
.box_list ul li:last-child {
  margin-right: 0;
  float: right;
}

.layout_view {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  border: 1px solid rgba(0, 0, 0, 0.08);
  padding: 4px 8px 3px 8px;
}
.layout_view a {
  color: rgba(0, 0, 0, 0.5);
}
.layout_view a.active {
  color: rgba(0, 0, 0, 0.8);
}
.layout_view a:hover {
  color: #fc5b62;
}

.switch-field {
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.08);
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
}
.switch-field input {
  position: absolute !important;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  width: 1px;
  border: 0;
  overflow: hidden;
}
.switch-field input:checked + label {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  color: rgba(0, 0, 0, 0.9);
}
.switch-field label {
  float: left;
  display: inline-block;
  min-width: 70px;
  color: rgba(0, 0, 0, 0.5);
  font-weight: 600;
  font-size: 12px;
  font-size: 0.75rem;
  text-align: center;
  text-shadow: none;
  padding: 10px 12px 8px 12px;
  line-height: 1;
  border-right: 1px solid rgba(0, 0, 0, 0.08);
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  margin: 0;
}
@media (max-width: 575px) {
  .switch-field label {
    min-width: inherit;
  }
}
.switch-field label:first-of-type {
  border-left: 0;
}
.switch-field label:last-of-type {
  border-right: 0;
}
.switch-field label:hover {
  cursor: pointer;
  color: #fc5b62;
}

#filters_col {
  background-color: #fff;
  padding: 20px 20px 15px 20px;
  margin-bottom: 25px;
  border: 1px solid #ededed;
}
#filters_col label {
  color: #333;
  font-weight: normal;
  font-size: 13px;
  font-size: 0.8125rem;
}

a#filters_col_bt {
  display: block;
  color: #333;
  position: relative;
  font-size: 16px;
  font-size: 1rem;
  font-weight: 600;
}
a#filters_col_bt:before {
  font-family: "ElegantIcons";
  content: "\66";
  font-size: 26px;
  font-size: 1.625rem;
  position: absolute;
  right: 0;
  top: 0;
  line-height: 1;
  font-weight: normal;
}

.filter_type h6 {
  border-top: 1px solid #ededed;
  margin: 15px 0;
  padding: 15px 0 0 0;
  font-size: 13px;
  font-size: 0.8125rem;
}
.filter_type ul {
  list-style: none;
  padding: 0;
  margin: 0 0 15px 0;
}
.filter_type ul li {
  margin-bottom: 5px;
}
.filter_type ul li:last-child {
  margin-bottom: 0;
}

a.boxed_list {
  text-align: center;
  padding: 30px;
  border: 1px solid #ededed;
  display: block;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  margin-bottom: 25px;
}
a.boxed_list i {
  font-size: 60px;
  font-size: 3.75rem;
  margin-bottom: 15px;
}
a.boxed_list h4 {
  font-size: 18px;
  font-size: 1.125rem;
  color: #333;
}
a.boxed_list p {
  color: #777;
  margin-bottom: 0;
}
a.boxed_list:hover i {
  color: #32a067;
}

/*-------- 2.4 Detail page --------*/
.secondary_nav {
  background: #fff;
  border-bottom: 1px solid #ededed;
  padding: 15px 0;
}
.secondary_nav.is_stuck {
  z-index: 99;
  width: 100% !important;
  left: 0;
}
.secondary_nav ul {
  margin-bottom: 0;
}
.secondary_nav ul li {
  display: inline-block;
  margin-right: 20px;
  font-weight: 500;
  font-size: 16px;
  font-size: 1rem;
}
.secondary_nav ul li a {
  color: rgba(0, 0, 0, 0.5);
}
.secondary_nav ul li a:hover {
  color: #fc5b62;
}
.secondary_nav ul li a.active {
  color: rgba(0, 0, 0, 0.9);
}
.secondary_nav ul li:last-child {
  display: none;
}
.secondary_nav ul li:last-child a {
  background-color: #fc5b62;
  color: #fff;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  padding: 5px 15px;
}
.secondary_nav ul li:last-child a:hover {
  background-color: #0054a6;
}
@media (max-width: 575px) {
  .secondary_nav ul li:last-child {
    display: inline-block;
    float: right;
    margin-right: 0;
  }
}

.intro_title h2 {
  display: inline-block;
}
.intro_title ul {
  float: right;
  margin: 10px 0 0 0;
}
.intro_title ul li {
  display: inline-block;
  margin-right: 20px;
  font-weight: 500;
}

section#description, section#reviews {
  border-bottom: 3px solid #ededed;
  margin-bottom: 45px;
}
section#description h2, section#reviews h2 {
  font-size: 24px;
  font-size: 1.5rem;
}
section#description h3, section#reviews h3 {
  font-size: 21px;
  font-size: 1.3125rem;
}
section#description h4, section#reviews h4 {
  font-size: 18px;
  font-size: 1.125rem;
}

section#reviews {
  border-bottom: none;
}

#review_summary {
  text-align: center;
  background-color: #0054a6;
  color: #fff;
  padding: 20px 10px;
  -webkit-border-radius: 5px 5px 5px 0;
  -moz-border-radius: 5px 5px 5px 0;
  -ms-border-radius: 5px 5px 5px 0;
  border-radius: 5px 5px 5px 0;
}
@media (max-width: 991px) {
  #review_summary {
    margin-bottom: 15px;
  }
}
#review_summary strong {
  font-size: 42px;
  font-size: 2.625rem;
  display: block;
  line-height: 1;
}
#review_summary em {
  font-style: normal;
  font-weight: 500;
  display: block;
}

.pictures_grid {
  margin-bottom: 45px;
}
.pictures_grid figure {
  margin: 0;
  overflow: hidden;
  position: relative;
  height: 120px;
  width: 120px;
  display: inline-block;
}
@media (max-width: 767px) {
  .pictures_grid figure {
    width: 80px;
    height: 80px;
  }
}
.pictures_grid figure a {
  display: block;
}
.pictures_grid figure a span {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
  background-color: black;
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  font-size: 26px;
  font-size: 1.625rem;
}
.pictures_grid figure a img {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%) scale(1);
  -moz-transform: translate(-50%, -50%) scale(1);
  -ms-transform: translate(-50%, -50%) scale(1);
  -o-transform: translate(-50%, -50%) scale(1);
  transform: translate(-50%, -50%) scale(1);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
  width: auto;
  height: 120px;
  z-index: 1;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
@media (max-width: 767px) {
  .pictures_grid figure a img {
    height: 80px;
  }
}
.pictures_grid figure a:hover img {
  -webkit-transform: translate(-50%, -50%) scale(1.05);
  -moz-transform: translate(-50%, -50%) scale(1.05);
  -ms-transform: translate(-50%, -50%) scale(1.05);
  -o-transform: translate(-50%, -50%) scale(1.05);
  transform: translate(-50%, -50%) scale(1.05);
}

.reviews-container .progress {
  margin-bottom: 12px;
}
.reviews-container .progress-bar {
  background-color: #0054a6;
}
.reviews-container .review-box {
  position: relative;
  margin-bottom: 25px;
  padding-left: 100px;
  min-height: 100px;
}
@media (max-width: 767px) {
  .reviews-container .review-box {
    padding-left: 0;
  }
}
.reviews-container .rev-thumb {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 80px;
  height: 80px;
  background: #ffffff;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  overflow: hidden;
}
.reviews-container .rev-thumb img {
  width: 80px;
  height: auto;
}
@media (max-width: 767px) {
  .reviews-container .rev-thumb {
    position: static;
    margin-bottom: 10px;
  }
}
.reviews-container .rev-content {
  position: relative;
  padding: 25px 25px 0 25px;
  border: 1px solid #ededed;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
}
.reviews-container .rev-info {
  font-size: 12px;
  font-size: 0.75rem;
  font-style: italic;
  color: #777;
  margin-bottom: 10px;
}

.box_detail {
  padding: 25px 25px 15px 25px;
  border: 1px solid #ededed;
  background-color: #f8f8f8;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  margin-bottom: 30px;
}
.box_detail .price {
  line-height: 1;
  border-bottom: 1px solid #ededed;
  margin: 0 -25px 25px -25px;
  padding: 0 25px 15px 25px;
}
.box_detail .price > span {
  font-size: 31px;
  font-size: 1.9375rem;
  font-weight: 600;
}
.box_detail .price > span > small {
  font-size: 11px;
  font-size: 0.6875rem;
  font-weight: 500;
}
.box_detail .price .score {
  float: right;
  margin-top: -5px;
}
.box_detail h3 {
  font-size: 20px;
  font-size: 1.25rem;
  margin: 25px 0 10px 0;
}
.box_detail ul {
  margin-bottom: 0;
}
.box_detail ul li {
  margin-bottom: 5px;
}
.box_detail ul li i {
  margin-right: 8px;
}
.box_detail figure {
  position: relative;
  background-color: #000;
}
.box_detail figure img {
  opacity: 0.8;
}
.box_detail figure span {
  position: absolute;
  display: block;
  left: 0;
  bottom: 10px;
  text-align: center;
  width: 100%;
  color: #fff;
  font-weight: 600;
}
.box_detail figure a i {
  background-color: black;
  background-color: rgba(0, 0, 0, 0.6);
  display: block;
  width: 60px;
  height: 60px;
  color: #fff;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  line-height: 60px;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -30px 0 0 -30px;
  font-size: 42px;
  font-size: 2.625rem;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  z-index: 9;
}
.box_detail figure:hover i {
  -webkit-transform: scale(1.3);
  -moz-transform: scale(1.3);
  -ms-transform: scale(1.3);
  -o-transform: scale(1.3);
  transform: scale(1.3);
}

.booking input {
  font-weight: 500;
  height: 45px;
}
.booking input:focus {
  box-shadow: none;
  border: 1px solid #d2d8dd;
}
.booking select {
  display: none;
}
.booking .panel-dropdown {
  background-color: #fff;
  font-size: 14px;
  font-size: 0.875rem;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  border: 1px solid #d2d8dd;
  padding-top: 10px;
  padding-bottom: 10px;
  margin: 0 0 10px 0;
}
@media (max-width: 991px) {
  .booking .panel-dropdown {
    height: inherit;
  }
}
.booking .panel-dropdown .panel-dropdown-content {
  top: 50px;
}
.booking .nice-select .current {
  font-weight: 500;
  color: #6f787f;
}
.booking .custom-select-form .nice-select:active, .booking .custom-select-form .nice-select.open, .booking .custom-select-form .nice-select:focus {
  border-color: #d2d8dd;
  outline: 0;
  box-shadow: none;
}
.booking .custom-select-form select {
  display: none;
}
.booking .form-group {
  margin: 0 0 10px 0;
}
.booking .form-group i {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  font-size: 18px;
  font-size: 1.125rem;
  position: absolute;
  background-color: #fff;
  height: 43px;
  top: 1px;
  right: 1px;
  padding-right: 10px;
  display: block;
  width: 20px;
  box-sizing: content-box;
  line-height: 43px;
  z-index: 9;
  color: #999;
}
.booking .form-group i.icon_lock_alt {
  top: 36px;
}

/* Detail page contact form */
.booking textarea {
  font-weight: 500;
  height: 100px;
  padding-right: 25px;
}
.booking textarea:focus {
  border: 1px solid #d2d8dd;
  box-shadow: none;
}

.booking i.loader {
  background-color: transparent;
  color: #fff;
  font-size: 14px;
  font-size: 0.875rem;
  height: auto;
  width: auto;
  padding: 0;
  right: 10px;
}

/* ------ Full view calendar on mobile ----------- */
@media (max-width: 767px) {
  .datepicker_mobile_full .daterangepicker {
    margin-top: 0 !important;
    border: none !important;
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
    width: 100% !important;
    z-index: 9999999 !important;
    height: 100% !important;
    overflow-y: scroll !important;
  }
  .datepicker_mobile_full .daterangepicker .calendar {
    max-width: 100% !important;
  }
}

/*Timeline --------------- */
ul.cbp_tmtimeline {
  margin: 45px 0 45px 0;
  padding: 0;
  list-style: none;
  position: relative;
}

/* The line */
.cbp_tmtimeline:before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  width: 2px;
  background: #ebeef3;
  left: 18.5%;
  margin-left: -2px;
}

/* The date/time */
.cbp_tmtimeline > li {
  position: relative;
}

.cbp_tmtimeline > li .cbp_tmtime {
  display: block;
  width: 23%;
  padding-right: 80px;
  position: absolute;
}

.cbp_tmtimeline > li .cbp_tmtime span {
  display: block;
  text-align: right;
}

.cbp_tmtimeline > li .cbp_tmtime span:first-child {
  font-size: 0.9em;
  color: #888;
  margin-bottom: 0;
}

.cbp_tmtimeline > li .cbp_tmtime span:last-child {
  font-size: 18px;
  font-weight: 500;
}

/* Right content */
img.thumb_visit {
  width: 70px;
  height: 70px;
  border: 2px solid #fff;
  margin-top: -5px;
  float: right;
  margin-left: 15px;
}
@media (max-width: 767px) {
  img.thumb_visit {
    display: none;
  }
}

.cbp_tmtimeline > li .cbp_tmlabel {
  margin: 0 0 15px 25%;
  background: #eef3f7;
  padding: 20px;
  position: relative;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}

.cbp_tmtimeline > li .cbp_tmlabel p {
  margin-bottom: 0;
  font-size: 13px;
  font-size: 0.8125rem;
}

.cbp_tmtimeline > li .cbp_tmlabel h4 {
  margin: 0 0 5px 0;
  padding: 0;
  font-size: 16px;
  font-size: 1rem;
}

/* The triangle */
.cbp_tmtimeline > li .cbp_tmlabel:after {
  right: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-right-color: #eef3f7;
  border-width: 10px;
  top: 15px;
}

/* The icons */
.cbp_tmtimeline > li .cbp_tmicon {
  width: 48px;
  height: 48px;
  font-weight: 500;
  background-color: #fff;
  text-transform: none;
  font-size: 24px;
  font-size: 1.5rem;
  line-height: 50px;
  position: absolute;
  color: #ff9933;
  border-radius: 50%;
  box-shadow: 0 0 0 2px #999;
  text-align: center;
  left: 18.5%;
  top: 0;
  margin: 0 0 0 -25px;
}

/* Media Queries */
@media screen and (max-width: 65.375em) {
  .cbp_tmtimeline > li .cbp_tmtime span:last-child {
    font-size: 1.5em;
  }
}
@media screen and (max-width: 47.2em) {
  .cbp_tmtimeline:before {
    display: none;
  }

  .cbp_tmtimeline > li .cbp_tmtime {
    width: 100%;
    position: relative;
    padding: 0 0 20px 0;
  }

  .cbp_tmtimeline > li .cbp_tmtime span {
    text-align: left;
  }

  .cbp_tmtimeline > li .cbp_tmlabel {
    margin: 0 0 30px 0;
    padding: 1em;
  }

  .cbp_tmtimeline > li .cbp_tmlabel:after {
    right: auto;
    left: 20px;
    border-right-color: transparent;
    border-bottom-color: #eef3f7;
    top: -20px;
  }

  .cbp_tmtimeline > li:nth-child(odd) .cbp_tmlabel:after {
    border-right-color: transparent;
    border-bottom-color: #eef3f7;
  }

  .cbp_tmtimeline > li .cbp_tmicon {
    position: relative;
    float: right;
    left: auto;
    margin: -65px 5px 0 0px;
  }
}
ul.hotel_facilities {
  list-style: none;
  margin: 0;
  padding: 0;
  -webkit-column-count: 2;
  -moz-column-count: 2;
  column-count: 2;
}
ul.hotel_facilities li {
  margin-bottom: 10px;
  display: inline-block;
  width: 100%;
}
ul.hotel_facilities li img {
  margin-right: 10px;
}

.room_type {
  padding: 30px 30px 10px 30px;
}
.room_type.first {
  padding: 0 30px 15px 30px;
}
.room_type.gray {
  background-color: #f8f8f8;
}
.room_type.last {
  padding: 30px 30px 0 30px;
}
@media (max-width: 767px) {
  .room_type h4 {
    margin-top: 25px;
  }
}

/*-------- 2.5 Login/register --------*/
#login_bg, #register_bg {
  background: url(../img/hero_in_bg_3.jpg) center center no-repeat fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  min-height: 100vh;
  width: 100%;
}

#register_bg {
  background: url(../img/hero_in_bg_2.jpg) center center no-repeat fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

#login figure, #register figure {
  text-align: center;
  border-bottom: 1px solid #ededed;
  margin: -45px -60px 25px -60px;
  padding: 20px 60px 25px 60px;
}
@media (max-width: 767px) {
  #login figure, #register figure {
    margin: -30px -30px 20px -30px;
    padding: 15px 60px 20px 60px;
  }
}
#login aside, #register aside {
  width: 430px;
  padding: 45px 60px 60px 60px;
  position: absolute;
  left: 0;
  top: 0;
  overflow-y: auto;
  background-color: #fff;
  min-height: 100vh;
}
@media (max-width: 767px) {
  #login aside, #register aside {
    width: 100%;
    padding: 30px;
  }
}
#login aside form, #register aside form {
  margin-bottom: 60px;
  display: block;
}
#login aside form .form-group input, #register aside form .form-group input {
  padding-left: 40px;
}
#login aside form .form-group i, #register aside form .form-group i {
  font-size: 21px;
  font-size: 1.3125rem;
  position: absolute;
  left: 12px;
  top: 34px;
  color: #ccc;
  width: 25px;
  height: 25px;
  display: block;
  font-weight: 400 !important;
}
#login aside .copy, #register aside .copy {
  text-align: center;
  position: absolute;
  height: 30px;
  left: 0;
  bottom: 30px;
  width: 100%;
  color: #999;
  font-weight: 500;
}

.access_social {
  margin-top: 45px;
}
@media (max-width: 767px) {
  .access_social {
    margin-top: 30px;
  }
}

.divider {
  text-align: center;
  height: 1px;
  margin: 30px 0 15px 0;
  background-color: #ededed;
}
.divider span {
  position: relative;
  top: -20px;
  background-color: #fff;
  display: inline-block;
  padding: 10px;
  font-style: italic;
}

a.social_bt {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  text-align: center;
  color: #fff;
  min-width: 200px;
  margin-bottom: 10px;
  display: block;
  padding: 12px;
  line-height: 1;
  position: relative;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}
a.social_bt:hover {
  -webkit-filter: brightness(115%);
  filter: brightness(115%);
}
a.social_bt.facebook:before, a.social_bt.google:before, a.social_bt.linkedin:before {
  font-family: 'ElegantIcons';
  position: absolute;
  left: 12px;
  top: 10px;
  font-size: 16px;
  font-size: 1rem;
  color: #fff;
}
a.social_bt.facebook {
  background-color: #3B5998;
}
a.social_bt.facebook:before {
  content: "\e093";
}
a.social_bt.google {
  background-color: #DC4E41;
}
a.social_bt.google:before {
  content: "\e096";
  top: 12px;
}
a.social_bt.linkedin {
  background-color: #0077B5;
}
a.social_bt.linkedin:before {
  content: "\e09d";
}

/*Password strength */
#pass-info {
  width: 100%;
  margin-bottom: 15px;
  color: #555;
  text-align: center;
  font-size: 12px;
  font-size: 0.75rem;
  padding: 5px 3px 3px 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
}
#pass-info.weakpass {
  border: 1px solid #FF9191;
  background: #FFC7C7;
  color: #94546E;
}
#pass-info.stillweakpass {
  border: 1px solid #FBB;
  background: #FDD;
  color: #945870;
}
#pass-info.goodpass {
  border: 1px solid #C4EEC8;
  background: #E4FFE4;
  color: #51926E;
}
#pass-info.strongpass {
  border: 1px solid #6ED66E;
  background: #79F079;
  color: #348F34;
}
#pass-info.vrystrongpass {
  border: 1px solid #379137;
  background: #48B448;
  color: #CDFFCD;
}

/* Checkbox style */
.container_check {
  display: block;
  position: relative;
  padding-left: 30px;
  line-height: 1.8;
  margin-bottom: 5px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.container_check input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.container_check input:checked ~ .checkmark {
  background-color: #0054a6;
  border: 1px solid transparent;
}
.container_check .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border: 1px solid #d2d8dd;
  background-color: #fff;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.container_check .checkmark:after {
  content: "";
  position: absolute;
  display: none;
  left: 7px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.container_check input:checked ~ .checkmark:after {
  display: block;
}

/*-------- 2.6 Map styles --------*/
.map {
  width: 100%;
  height: 500px;
}
.map.map_single {
  height: 400px;
}

.infoBox {
  -webkit-animation: fadeIn 0.9s;
  animation: fadeIn 0.9s;
  padding-right: 50px;
}
.infoBox > img {
  position: absolute !important;
  right: 60px !important;
  top: 10px !important;
  z-index: 9999;
  width: 20px;
  height: 20px;
  display: block;
  cursor: pointer;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.marker_info {
  width: 240px;
  height: 270px;
  border-radius: 5px;
  text-align: left;
  background: #000;
  background: white;
  position: relative;
  z-index: 999;
  font-family: "Poppins", Helvetica, sans-serif;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.12);
}
.marker_info img {
  display: block;
  -webkit-border-radius: 5px 5px 0 0;
  -moz-border-radius: 5px 5px 0 0;
  -ms-border-radius: 5px 5px 0 0;
  border-radius: 5px 5px 0 0;
}
.marker_info h3 {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.1;
  font-weight: 500;
  margin-bottom: 3px;
  color: #444;
}
.marker_info em {
  display: inline-block;
  font-size: 12px;
  font-size: 0.75rem;
  color: #999;
}
.marker_info span {
  display: block;
  padding: 20px 20px 0 20px;
  font-size: 13px;
  font-size: 0.8125rem;
  line-height: 1.2;
  color: #fff;
  position: relative;
}
.marker_info span strong {
  display: block;
  font-weight: 500;
}
.marker_info:after {
  right: 100%;
  top: 56%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: transparent;
  border-right-color: white;
  border-width: 12px;
  margin-top: -12px;
}

a.btn_infobox_detail {
  position: absolute;
  top: 20px;
  right: 15px;
  width: 20px;
  height: 20px;
}

a.btn_infobox_detail:before, .btn_infobox_get_directions:before, a.btn_infobox_phone:before {
  font-style: normal;
  font-weight: normal;
  font-family: "ElegantIcons";
  font-size: 20px;
  font-size: 1.25rem;
  cursor: pointer;
}

a.btn_infobox_detail:before {
  content: "\70";
  color: #ccc;
}
a.btn_infobox_detail:before:hover {
  color: #fc5b62;
}

.btn_infobox_get_directions, a.btn_infobox_phone {
  border: none;
  display: inline-block;
  font-weight: 500;
  color: #fc5b62;
  background: none;
  cursor: pointer;
  font-size: 13px;
  font-size: 0.8125rem;
  position: relative;
  padding-left: 20px;
  margin-bottom: 10px;
  outline: none;
}
.btn_infobox_get_directions:focus, a.btn_infobox_phone:focus {
  outline: none;
}

.btn_infobox_get_directions:hover, a.btn_infobox_phone:hover {
  color: #333;
}

.btn_infobox_get_directions:before {
  font-size: 14px;
  font-size: 0.875rem;
  content: "\e080";
  position: absolute;
  left: 0;
  top: 0;
}

a.btn_infobox_phone:before {
  font-size: 14px;
  content: "\e090";
  position: absolute;
  left: 0;
  top: -2px;
}

span.infobox_rate {
  display: inline-block;
  margin: -44px 0 0 -20px;
  float: left;
  background-color: #0054a6;
  padding: 5px 8px;
  font-weight: 500;
  text-align: left;
  font-size: 12px;
  font-size: 0.75rem;
}

/* Cluster styles */
.cluster img {
  display: none;
}

.cluster-visible {
  text-align: center;
  font-size: 15px !important;
  color: #fff !important;
  font-weight: 500;
  border-radius: 50%;
  width: 36px !important;
  height: 36px !important;
  line-height: 36px !important;
  background-color: #fc5b62;
}

.cluster-visible:before {
  border: 7px solid #fc5b62;
  opacity: 0.2;
  box-shadow: inset 0 0 0 4px #fc5b62;
  content: '';
  position: absolute;
  border-radius: 50%;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  animation: cluster-animation 2.5s infinite;
}

@keyframes cluster-animation {
  0%,100% {
    transform: scale(1.3) rotate(0deg);
  }
  50% {
    transform: scale(1.5) rotate(90deg);
  }
}
/*-------- 2.7 Contacts--------*/
.map_contact {
  width: 100%;
  height: 465px;
}
@media (max-width: 991px) {
  .map_contact {
    height: 350px;
    margin-bottom: 45px;
  }
}

.contact_info {
  background-color: #121921;
  color: #fff;
  padding: 30px 0;
  text-align: center;
}
.contact_info ul {
  margin: 0;
  padding: 0;
}
.contact_info ul li {
  float: left;
  width: 33.33%;
  position: relative;
  padding: 0 20px;
}
.contact_info ul li span {
  opacity: 0.7;
}
.contact_info ul li h4 {
  color: #fff;
  font-size: 18px;
  font-size: 1.125rem;
  color: #fc5b62;
}
.contact_info ul li i {
  color: #fff;
  font-size: 46px;
  font-size: 2.875rem;
  margin-bottom: 10px;
}
@media (max-width: 767px) {
  .contact_info ul li {
    width: 100%;
    float: none;
    margin-bottom: 35px;
    padding: 0;
  }
  .contact_info ul li:last-child {
    margin-bottom: 5px;
  }
}

.error_message {
  font-weight: 500;
  color: #e3464c;
}

/*-------- 2.8 404 page --------*/
#error_page {
  width: 100%;
  height: 650px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #0054a6 url(../img/pattern_1.svg) center bottom repeat-x;
  color: #fff;
}
@media (max-width: 767px) {
  #error_page {
    background-size: auto 300px;
  }
}
#error_page h2 {
  margin-top: 30px;
  font-size: 140px;
  font-size: 8.75rem;
  color: #fff;
  text-transform: uppercase;
  font-weight: bold;
  line-height: 1;
}
#error_page h2 i {
  color: #FFC107;
}
@media (max-width: 767px) {
  #error_page h2 {
    font-size: 72px;
    font-size: 4.5rem;
  }
}
#error_page p {
  font-size: 21px;
  font-size: 1.3125rem;
}
@media (max-width: 767px) {
  #error_page p {
    font-size: 18px;
    font-size: 1.125rem;
  }
}
@media (max-width: 767px) {
  #error_page {
    padding: 0;
    height: 500px;
  }
}

.search_bar_error {
  position: relative;
  margin-bottom: 60px;
}
.search_bar_error input[type='text'] {
  border: 0;
  height: 50px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  padding-left: 15px;
  -webkit-box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.15);
}
.search_bar_error input[type='submit'] {
  position: absolute;
  right: -1px;
  color: #fff;
  font-weight: 600;
  top: 0;
  border: 0;
  padding: 0 25px;
  height: 50px;
  cursor: pointer;
  -webkit-border-radius: 0 3px 3px 0;
  -moz-border-radius: 0 3px 3px 0;
  -ms-border-radius: 0 3px 3px 0;
  border-radius: 0 3px 3px 0;
  background-color: #fc5b62;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.search_bar_error input[type='submit']:hover {
  background-color: #FFC107;
  color: #333;
}

/*-------- 2.9 Cart section --------*/
.cart_section {
  background: #0054a6 url(../img/pattern_1.svg) center bottom repeat-x;
}
@media (max-width: 767px) {
  .cart_section {
    background-size: auto 300px;
  }
}
.cart_section.last {
  height: 600px;
}
@media (max-width: 767px) {
  .cart_section.last {
    height: 500px;
  }
}

.bs-wizard {
  width: 100%;
  margin: 50px auto 0;
}
.bs-wizard > .bs-wizard-step {
  padding: 0;
  position: relative;
  width: 33.33%;
  float: left;
}
.bs-wizard > .bs-wizard-step.complete > .progress > .progress-bar {
  width: 100%;
}
.bs-wizard > .bs-wizard-step.active > .progress > .progress-bar {
  width: 50%;
}
.bs-wizard > .bs-wizard-step:first-child.active > .progress > .progress-bar {
  width: 0%;
}
.bs-wizard > .bs-wizard-step:last-child.active > .progress > .progress-bar {
  width: 100%;
}
.bs-wizard > .bs-wizard-step.disabled > .bs-wizard-dot {
  background-color: #0054a6;
}
.bs-wizard > .bs-wizard-step.disabled > .bs-wizard-dot:after {
  opacity: 0;
}
.bs-wizard > .bs-wizard-step:first-child > .progress {
  left: 50%;
  width: 50%;
}
.bs-wizard > .bs-wizard-step:last-child > .progress {
  width: 50%;
}
.bs-wizard > .bs-wizard-step .bs-wizard-stepnum {
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: 600;
  text-transform: uppercase;
}
.bs-wizard > .bs-wizard-step > .bs-wizard-dot {
  position: absolute;
  width: 50px;
  height: 50px;
  display: block;
  top: 36px;
  left: 50%;
  margin-top: -5px;
  margin-left: -25px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  border: 2px solid #fff;
  background-color: #0054a6;
}
.bs-wizard > .bs-wizard-step > .bs-wizard-dot:after {
  content: '\e903';
  font-family: 'Fontello';
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  border-radius: 50px;
  position: absolute;
  top: -2px;
  left: 9px;
  font-size: 32px;
  font-size: 2rem;
  color: #FFC107;
}
.bs-wizard > .bs-wizard-step > .progress {
  position: relative;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  border-radius: 0;
  height: 2px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  margin: 34px 0;
}
.bs-wizard > .bs-wizard-step > .progress > .progress-bar {
  width: 0px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  background: #fff;
}

.bs-wizard > .bs-wizard-step.disabled a.bs-wizard-dot {
  pointer-events: none;
}

.table.cart-list {
  margin-bottom: 0;
}
.table.cart-list td {
  vertical-align: middle;
  padding: 15px;
}
.table.cart-list td strong {
  font-weight: 500;
}

.table.cart-list th,
.table.options_cart th {
  background-color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  border: 0;
  padding-top: 0;
}

.table.cart-list tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.025);
}

.table > :not(:first-child) {
  border-top: 1px solid #ddd;
}

@media (max-width: 991px) {
  .table.cart-list,
  .table.cart-list thead,
  .table.cart-list tbody,
  .table.cart-list th,
  .table.cart-list td,
  .table.cart-list tr {
    display: block;
    border: 0;
  }

  .table.cart-list thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  .table.cart-list tr {
    border: 1px solid #eee;
  }

  .table.cart-list td {
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 50%;
  }
  .table.cart-list td:before {
    position: absolute;
    top: 12px;
    left: 12px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
  }

  .table.cart-list td:nth-of-type(1):before, .table.cart-list td:nth-of-type(2):before, .table.cart-list td:nth-of-type(3):before, .table.cart-list td:nth-of-type(4):before {
    font-weight: 600;
    color: #111;
  }

  .table.cart-list td:nth-of-type(1):before {
    content: "Item";
  }

  .table.cart-list td:nth-of-type(2):before {
    content: "Discount";
  }

  .table.cart-list td:nth-of-type(3):before {
    content: "Total";
  }

  .table.cart-list td:nth-of-type(4):before {
    content: "Actions";
  }

  td.options {
    text-align: left !important;
  }
}
.thumb_cart {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  overflow: hidden;
  width: 60px;
  height: 60px;
  margin-right: 10px;
  float: left;
}
.thumb_cart img {
  width: 60px;
  height: auto;
}
@media (max-width: 991px) {
  .thumb_cart {
    display: none;
  }
}

.item_cart {
  display: inline-block;
  margin: 20px 0 0 15px;
  font-weight: 500;
}
@media (max-width: 991px) {
  .item_cart {
    margin: 5px 0 0 0;
  }
}

#total_cart {
  font-size: 24px;
  font-size: 1.5rem;
  font-weight: 500;
  border-bottom: 1px solid #ededed;
  margin: 0 -25px 20px -25px;
  padding: 0 25px 15px 25px;
  line-height: 1;
}
#total_cart span {
  font-weight: 600;
}

ul.cart_details {
  margin: 0 0 25px 0;
  padding: 0 0 15px 0;
  border-bottom: 1px solid #ededed;
}
ul.cart_details li {
  font-weight: 500;
}
ul.cart_details li span {
  float: right;
}

.form_title {
  position: relative;
  padding-left: 55px;
  margin-bottom: 10px;
}

.form_title {
  position: relative;
  padding-left: 55px;
  margin-bottom: 0;
}
.form_title h3 {
  margin: 0;
  padding: 0;
  font-size: 21px;
  font-size: 1.3125rem;
}
.form_title h3 strong {
  background-color: #fc5b62;
  text-align: center;
  width: 40px;
  height: 40px;
  display: inline-block;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  color: #fff;
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 42px;
  text-align: center;
  position: absolute;
  left: 0;
  top: -5px;
}
.form_title p {
  color: #999;
  margin: 0;
  padding: 0;
  font-size: 12px;
  font-size: 0.75rem;
  line-height: 14px;
}

.cards-payment {
  margin-top: 28px;
}
@media (max-width: 991px) {
  .cards-payment {
    display: none;
  }
}

.box_cart .step {
  padding: 15px 55px 0 55px;
  margin: 0 0 0 0;
}
@media (max-width: 991px) {
  .box_cart .step {
    padding: 15px 0 0 0;
  }
}

.message {
  background-color: #f8f8f8;
  padding: 15px;
  margin-bottom: 30px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
}
.message p {
  margin: 0;
}

#confirm {
  text-align: center;
  font-size: 16px;
  font-size: 1rem;
  margin-top: 20px;
}
#confirm h4 {
  color: #fff;
}
#confirm p {
  font-size: 16px !important;
  font-size: 16px !important/16pxrem;
  margin-bottom: 0;
}

.box_cart {
  margin-bottom: 25px;
}
.box_cart hr {
  margin: 30px -25px 30px -25px;
}
.box_cart label {
  font-weight: 500;
}
.box_cart a.btn_1.outline, .box_cart .btn_1.outline {
  border: 2px solid #999;
  color: #999;
  padding: 12px 40px;
}
.box_cart a.btn_1.outline:hover, .box_cart .btn_1.outline:hover {
  color: #fff;
  border: 2px solid #0054a6;
}

.cart-options {
  position: relative;
  padding: 25px 0px 0px 0px;
}
@media (max-width: 991px) {
  .cart-options .fix_mobile {
    float: left !important;
  }
}

.apply-coupon .form-group {
  position: relative;
  float: left;
  margin-right: 10px;
}
.apply-coupon .form-control {
  padding: 10px 12px;
}

/*-------- 2.10 Media Gallery --------*/
.grid ul {
  margin: 0 0 25px 0;
  padding: 0;
  width: 100%;
  text-align: center;
}
.grid ul li {
  display: inline-block;
  margin: 0;
  padding: 0;
  margin: -3px;
  min-height: 100%;
  width: 25%;
  background-color: #000;
  list-style: none;
}
@media (max-width: 991px) {
  .grid ul li {
    width: 50%;
  }
}
@media (max-width: 767px) {
  .grid ul li {
    width: 100%;
    min-height: 100%;
    float: none;
  }
}
.grid ul li figure {
  position: relative;
  overflow: hidden;
  margin: -3px;
}
.grid ul li figure img {
  width: 100%;
  height: 100%;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.grid ul li figure:hover img, .grid ul li figure:focus img {
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
  transform: scale(1.1);
}
.grid ul li figure:hover figcaption, .grid ul li figure:focus figcaption {
  opacity: 1;
}
.grid ul li figure:hover figcaption .caption-content, .grid ul li figure:focus figcaption .caption-content {
  -webkit-transform: translate(0px, 0px);
  -moz-transform: translate(0px, 0px);
  -ms-transform: translate(0px, 0px);
  -o-transform: translate(0px, 0px);
  transform: translate(0px, 0px);
}
.grid ul li figcaption {
  position: absolute;
  top: 0;
  left: 0;
  padding: 15% 0;
  width: 100%;
  height: 100%;
  background-color: black;
  background-color: rgba(0, 0, 0, 0.6);
  text-align: center;
  font-size: 14px;
  font-size: 0.875rem;
  opacity: 0;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.grid ul li figcaption .caption-content {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -30px;
  margin-left: -100px;
  width: 200px;
  -webkit-transform: translate(0px, 15px);
  -moz-transform: translate(0px, 15px);
  -ms-transform: translate(0px, 15px);
  -o-transform: translate(0px, 15px);
  transform: translate(0px, 15px);
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.grid ul li figcaption a {
  color: #fff;
}
.grid ul li figcaption a:hover, .grid ul li figcaption a:focus {
  color: #fc5b62;
}
.grid ul li figcaption i {
  font-size: 30px;
  font-size: 1.875rem;
}
.grid ul li figcaption p {
  margin-bottom: 0;
  margin-top: 10px;
  text-transform: uppercase;
  font-weight: 400;
}

.mfp-zoom-in .mfp-with-anim {
  opacity: 0;
  transition: all 0.2s ease-in-out;
  transform: scale(0.8);
}
.mfp-zoom-in.mfp-bg {
  opacity: 0;
  transition: all 0.3s ease-out;
}
.mfp-zoom-in.mfp-ready .mfp-with-anim {
  opacity: 1;
  transform: scale(1);
}
.mfp-zoom-in.mfp-ready.mfp-bg {
  opacity: 0.8;
}
.mfp-zoom-in.mfp-removing .mfp-with-anim {
  transform: scale(0.8);
  opacity: 0;
}
.mfp-zoom-in.mfp-removing.mfp-bg {
  opacity: 0;
}

/*-------- 2.11 Faq --------*/
.box_style_cat {
  background: #fff;
  border: 1px solid #ededed;
  margin-bottom: 25px;
  -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
}
@media (max-width: 991px) {
  .box_style_cat {
    display: none;
  }
}

ul#cat_nav {
  list-style: none;
  margin: 0;
  padding: 0;
}
ul#cat_nav li {
  border-bottom: 1px solid #ededed;
}
ul#cat_nav li i {
  font-size: 16px;
  font-size: 1rem;
  margin: 2px 10px 0 0;
  float: left;
}
ul#cat_nav li:last-child {
  border-bottom: 0;
  padding-bottom: 0;
}
ul#cat_nav li a {
  position: relative;
  color: #555;
  display: block;
  padding: 15px 10px;
}
ul#cat_nav li a:after {
  font-family: "fontello";
  content: "\e89b";
  position: absolute;
  right: 15px;
  top: 15px;
}
ul#cat_nav li a span {
  font-size: 11px;
  font-size: 0.6875rem;
  color: #999;
}
ul#cat_nav li a:hover, ul#cat_nav li a#active, ul#cat_nav li a.active {
  color: #fc5b62;
}

.hero_in form {
  animation-delay: 1.3s;
  -webkit-animation-delay: 1.3s;
  -moz-animation-delay: 1.3s;
}

a.box_topic {
  text-align: center;
  background: #fff;
  padding: 40px 30px 15px 30px;
  display: block;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
  color: #555;
  margin-bottom: 30px;
  overflow: hidden;
}
@media (max-width: 767px) {
  a.box_topic {
    padding: 20px 20px 0 20px;
  }
}
a.box_topic:hover {
  -webkit-transform: translateY(-10px);
  -moz-transform: translateY(-10px);
  -ms-transform: translateY(-10px);
  -o-transform: translateY(-10px);
  transform: translateY(-10px);
}
a.box_topic i {
  font-size: 60px;
  font-size: 3.75rem;
  color: #32a067;
  margin-bottom: 10px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  border-radius: 50px;
  background-color: #2c8e5b;
  background-color: rgba(50, 160, 103, 0.05);
  width: 100px;
  height: 100px;
  line-height: 100px;
  margin-bottom: 15px;
}
a.box_topic h3 {
  font-size: 18px;
  font-size: 1.125rem;
}

.list_articles ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.list_articles ul li {
  float: left;
  width: 45%;
  margin-right: 45px;
  padding: 15px 0;
  border-bottom: 1px solid #ededed;
}
.list_articles ul li a {
  color: #555;
  display: block;
}
.list_articles ul li a:hover {
  color: #fc5b62;
  -webkit-transform: translateX(5px);
  -moz-transform: translateX(5px);
  -ms-transform: translateX(5px);
  -o-transform: translateX(5px);
  transform: translateX(5px);
}
.list_articles ul li i {
  margin-right: 10px;
  font-size: 16px;
  font-size: 1rem;
  position: relative;
  top: 2px;
}
@media (max-width: 991px) {
  .list_articles ul li {
    float: none;
    width: 100%;
    margin-right: 0;
  }
}

ul.list_3 {
  list-style: none;
  margin: 0;
  padding: 0;
}
ul.list_3 li {
  margin-bottom: 0;
  position: relative;
  padding-left: 20px;
}
ul.list_3 li strong {
  font-weight: 500;
}
ul.list_3 li:before {
  font-family: "fontello";
  content: "\ea3e";
  position: absolute;
  left: 0;
  top: 0;
}

.box_general {
  border: 1px solid #ededed;
  padding: 25px 25px 5px 25px;
  margin-bottom: 25px;
  -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
}

/*-------- 2.12 Adventure --------*/
.header-video.adventure .wrapper {
  padding-top: 60px;
  text-align: left;
  font-size: 14px;
  font-size: 0.875rem;
}
.header-video.adventure .wrapper small {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
  font-size: 0.875rem;
  display: inline-block;
  margin-bottom: 15px;
}
@media (max-width: 767px) {
  .header-video.adventure .wrapper h3 {
    font-size: 36px !important;
    margin-top: 0 !important;
  }
}
.header-video.adventure .wrapper p {
  max-width: 400px;
}
@media (max-width: 767px) {
  .header-video.adventure .wrapper p {
    font-size: 21px !important;
  }
}

@media (max-width: 1024px) {
  .header-video.adventure #hero_video {
    background: #ccc url(../img/bg_fallback_video_adventure.jpg) center right;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  .header-video.adventure #hero_video > div {
    background-color: black;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .header-video.adventure {
    background: none;
  }
}
a.grid_item_adventure {
  display: block;
  margin-bottom: 25px;
}
a.grid_item_adventure .info {
  position: absolute;
  width: 100%;
  z-index: 9;
  display: block;
  padding: 25px 20px 5px 20px;
  color: #fff;
  left: 0;
  bottom: 0;
  background: transparent;
  background: -webkit-linear-gradient(top, transparent, #000);
  background: linear-gradient(to bottom, transparent, #000);
  box-sizing: border-box;
}
a.grid_item_adventure .info h3 {
  color: #fff;
  font-size: 18px;
  font-size: 1.125rem;
}
a.grid_item_adventure .info p {
  color: #fff;
  margin-bottom: 0;
  font-size: 15px;
  font-size: 0.9375rem;
}
a.grid_item_adventure figure {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
}
a.grid_item_adventure figure .score {
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 9;
}
a.grid_item_adventure figure em {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  font-size: 0.75rem;
  text-transform: uppercase;
}
a.grid_item_adventure figure img {
  width: 100%;
  height: auto;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
  transform: scale(1.1);
}
a.grid_item_adventure:hover figure img {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}

a.grid_item.latest_adventure h3 {
  font-size: 18px;
  font-size: 1.125rem;
}
a.grid_item.latest_adventure em {
  text-transform: uppercase;
  font-size: 12px;
  font-size: 0.75rem;
  font-weight: 500;
  font-style: normal;
}

.banner {
  background: url(../img/banner_bg_desktop.jpg) center center no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: 100%;
  height: 420px;
  margin-bottom: 60px;
  position: relative;
}
@media (max-width: 767px) {
  .banner {
    background: url(../img/banner_bg_mobile.jpg) center center no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
}
.banner .wrapper {
  color: #fff;
  padding: 60px;
}
@media (max-width: 767px) {
  .banner .wrapper {
    padding: 30px;
  }
}
.banner .wrapper small {
  text-transform: uppercase;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: 500;
}
.banner .wrapper h3 {
  color: #fff;
  font-size: 32px;
  font-size: 2rem;
  margin: 15px 0 0 0;
}
.banner .wrapper p {
  font-size: 21px;
  font-size: 1.3125rem;
}

.call_section.adventure {
  background: url(../img/bg_call_action_adventure.jpg) center center no-repeat fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.adventure_feat h3 {
  font-size: 21px;
  font-size: 1.3125rem;
  margin-top: 20px;
}

.hero_in.adventure_detail {
  text-align: left;
  height: 600px;
}
@media (max-width: 767px) {
  .hero_in.adventure_detail {
    height: 550px;
  }
}
.hero_in.adventure_detail:before {
  background: url(../img/bg_adventure_detail.jpg) center center no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.hero_in.adventure_detail .container {
  position: relative;
  height: 100%;
}
.hero_in.adventure_detail .wrapper a.btn_photos {
  left: 30px;
  bottom: 35px;
}
.hero_in.adventure_detail .wrapper .main_info {
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 999;
  padding-bottom: 30px;
}
.hero_in.adventure_detail .wrapper .main_info em {
  font-style: normal;
  text-transform: uppercase;
}
.hero_in.adventure_detail .wrapper .main_info h1 {
  color: #fff;
  margin: 0;
  text-transform: none;
  font-size: 36px;
  font-size: 2.25rem;
  font-weight: 600;
}
@media (max-width: 767px) {
  .hero_in.adventure_detail .wrapper .main_info h1 {
    font-size: 32px;
    font-size: 2rem;
  }
}
.hero_in.adventure_detail .wrapper .main_info h3 {
  color: #fff;
  margin: 0;
  font-size: 26px;
  font-size: 1.625rem;
  font-weight: 400;
}
@media (max-width: 767px) {
  .hero_in.adventure_detail .wrapper .main_info h3 {
    margin-top: 10px;
    font-size: 21px;
    font-size: 1.3125rem;
  }
}
.hero_in.adventure_detail .wrapper .main_info p {
  font-weight: 300;
  margin-bottom: 0;
  font-size: 14px;
  font-size: 0.875rem;
}
.hero_in.adventure_detail .wrapper .main_info p strong {
  font-weight: 600;
}
.hero_in.adventure_detail .wrapper .main_info ul {
  margin: 10px 0 0 0;
  padding-bottom: 0;
  list-style: none;
}
.hero_in.adventure_detail .wrapper .main_info ul li {
  display: inline-block;
  line-height: 1.2;
  margin-right: 30px;
}
.hero_in.adventure_detail .wrapper .main_info ul li:last-child {
  margin-right: 0;
}
.hero_in.adventure_detail .wrapper .main_info ul li span {
  display: block;
  margin-top: 5px;
  font-size: 12px;
  font-size: 0.75rem;
}
.hero_in.adventure_detail .wrapper .main_info ul li i {
  margin-bottom: 5px;
  display: block;
  font-size: 24px;
  font-size: 1.5rem;
}

.adventure_detail .container {
  position: relative;
  height: 100%;
}
.adventure_detail .main_info {
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 999;
  padding: 0 15px;
  padding-bottom: 25px;
}

.adventure_description h2 {
  font-size: 26px;
  font-size: 1.625rem;
}

ul.list_feat {
  margin: 0 0 25xp 0;
  padding: 0;
  list-style: none;
}
ul.list_feat li {
  float: left;
  width: 30%;
  margin: 0 15px 15px 0;
  border: 1px solid #ededed;
  padding: 25px;
  min-height: 180px;
}
@media (max-width: 991px) {
  ul.list_feat li {
    width: 45%;
  }
}
@media (max-width: 575px) {
  ul.list_feat li {
    width: 100%;
    min-height: inherit;
  }
}
ul.list_feat li h4 {
  font-size: 16px;
  font-size: 1rem;
  margin: 0;
}
ul.list_feat li p {
  margin: 0;
  color: #777;
}

/* Opacity mask when left open */
.layer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-width: 100%;
  min-height: 100%;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.35);
  -moz-transition: all 0.1s ease;
  -o-transition: all 0.1s ease;
  -webkit-transition: all 0.1s ease;
  -ms-transition: all 0.1s ease;
  transition: all 0.1s ease;
  opacity: 0;
  visibility: hidden;
}

.layer-is-visible {
  opacity: 1;
  visibility: visible;
}

#panel_dates {
  right: -100%;
  bottom: 0;
  width: 400px;
  height: 100%;
  opacity: 0;
  position: fixed;
  background-color: #fff;
  transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  -webkit-transition: all 0.3s ease-in-out 0s;
  z-index: 9999999;
  -webkit-box-shadow: 1px 0px 5px 0px rgba(50, 50, 50, 0.55);
  -moz-box-shadow: 1px 0px 5px 0px rgba(50, 50, 50, 0.55);
  box-shadow: 1px 0px 5px 0px rgba(50, 50, 50, 0.55);
  overflow: auto;
  overflow-x: hidden;
}
@media (max-width: 767px) {
  #panel_dates {
    width: 100%;
  }
}
#panel_dates.show {
  visibility: visible;
  opacity: 1;
  right: 0;
}
#panel_dates a.aside-panel-bt {
  font-size: 24px;
  position: relative;
  left: 10px;
  top: 0;
  width: 24px;
  height: 24px;
  color: #ccc;
}
#panel_dates a.aside-panel-bt:hover {
  color: #333;
}

.additional_txt {
  padding: 0 25px 10px 25px;
}
.additional_txt p {
  font-size: 12px;
  font-size: 0.75rem;
  line-height: 1.4;
  margin-bottom: 10px;
}

.timeline {
  padding-left: 25px;
  border-left: 1px solid #ededed;
}
.timeline h3 {
  font-size: 18px;
  font-size: 1.125rem;
  position: relative;
}
.timeline h3:before {
  font-family: 'ElegantIcons';
  content: "\5c";
  position: absolute;
  left: -35px;
  top: -3px;
  font-size: 21px;
  font-size: 1.3125rem;
  color: #ccc;
  background-color: #fff;
}

.pictures {
  margin-bottom: 45px;
}
.pictures figure {
  margin: 0;
  overflow: hidden;
  position: relative;
  height: 100px;
  width: 100px;
  display: inline-block;
}
.pictures figure a {
  display: block;
}
.pictures figure a span {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
  background-color: black;
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  font-size: 26px;
  font-size: 1.625rem;
}
.pictures figure a img {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%) scale(1);
  -moz-transform: translate(-50%, -50%) scale(1);
  -ms-transform: translate(-50%, -50%) scale(1);
  -o-transform: translate(-50%, -50%) scale(1);
  transform: translate(-50%, -50%) scale(1);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
  width: 100%;
  z-index: 1;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.pictures figure a:hover img {
  -webkit-transform: translate(-50%, -50%) scale(1.05);
  -moz-transform: translate(-50%, -50%) scale(1.05);
  -ms-transform: translate(-50%, -50%) scale(1.05);
  -o-transform: translate(-50%, -50%) scale(1.05);
  transform: translate(-50%, -50%) scale(1.05);
}

/*-------- 2.13 Home AirBnb --------*/
/* Home single hero */
.hero_single.version_3 {
  height: 690px;
}
.hero_single.version_3:before {
  background: url(../img/home_section_3.jpg) center center no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.hero_single.version_3 .wrapper {
  background-color: black;
  background-color: rgba(0, 0, 0, 0.45);
}
.hero_single.version_3 .wrapper .main_search {
  background-color: #fff;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  width: 460px;
  text-align: left;
  padding: 30px 30px 15px 30px;
}
@media (max-width: 767px) {
  .hero_single.version_3 .wrapper .main_search {
    width: auto;
  }
}
.hero_single.version_3 .wrapper .main_search h3 {
  color: #333;
  font-size: 28px;
  font-size: 1.75rem;
  text-transform: none;
  font-weight: 500;
}
.hero_single.version_3 .wrapper .main_search p {
  color: #555;
  font-size: 18px;
  font-size: 1.125rem;
  padding: 0;
  margin-bottom: 20px;
}
.hero_single.version_3 .wrapper .main_search .custom-search-input-2 {
  background-color: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.hero_single.version_3 .wrapper .main_search .custom-search-input-2 .form-group {
  margin-bottom: 15px;
}
.hero_single.version_3 .wrapper .main_search .custom-search-input-2 i {
  line-height: 48px;
  top: 1px;
  right: 1px;
  padding-right: 12px;
  height: 46px;
}
.hero_single.version_3 .wrapper .main_search .custom-search-input-2 input {
  border: 1px solid #d2d8dd;
}
.hero_single.version_3 .wrapper .main_search .custom-search-input-2 .panel-dropdown {
  border: 1px solid #d2d8dd;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  height: 50px;
  padding: 14px 12px 0 15px;
}
.hero_single.version_3 .wrapper .main_search .custom-search-input-2 input[type='submit'] {
  margin: 20px 0 0 0;
  border: none;
  width: auto;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
}

/*-------- 2.14 Half screen map --------*/
/* Header */
header.header.map_view {
  padding: 12px 20px;
  background-color: #fff;
  border-bottom: 1px solid #ededed;
}
@media (max-width: 991px) {
  header.header.map_view {
    padding: 15px 15px 5px 15px;
  }
}
header.header.map_view ul#top_menu li a.login:before, header.header.map_view ul#top_menu li a.wishlist_bt_top:before, header.header.map_view ul#top_menu li a.cart-menu-btn:before {
  color: #444;
}
header.header.map_view .main-menu > ul > li span > a {
  color: #444;
}
header.header.map_view .main-menu > ul > li span:hover > a {
  opacity: 1;
  color: #fc5b62;
}
header.header.map_view .hamburger-inner, header.header.map_view .hamburger-inner::before, header.header.map_view .hamburger-inner::after {
  background-color: #444;
}

.full-height {
  height: 100%;
}
@media (max-width: 991px) {
  .full-height {
    height: auto;
  }
}

.row-height {
  height: 100%;
  min-height: 100%;
  padding-top: 62px;
}
@media (max-width: 991px) {
  .row-height {
    height: auto;
    padding-top: 50px;
  }
}

.map-right {
  height: 100%;
  min-height: 100%;
  padding: 0px;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 0;
  z-index: 9999;
}
@media (max-width: 991px) {
  .map-right {
    height: 400px;
    position: static;
    min-height: inherit;
  }
}
.map-right #map {
  height: 100%;
  width: 100%;
}

.content-left {
  height: 100%;
  min-height: 100%;
  overflow-y: scroll;
  background-color: #f8f8f8;
}
@media (max-width: 991px) {
  .content-left {
    padding-top: 0;
  }
}

.search_map_wp {
  padding: 10px 0 10px 0;
  display: none;
  width: 100%;
}
.search_map_wp .nice-select {
  margin-bottom: 5px;
  border: none;
}
.search_map_wp .form-control {
  margin-bottom: 10px;
}
.search_map_wp input[type='submit'] {
  background-color: #FFC107;
  color: #222;
}
.search_map_wp input[type='submit']:hover {
  background-color: #32a067;
  color: #fff;
}

a.btn_search_map_view {
  background: url(../img/search.svg) no-repeat center center;
  background-size: 21px 21px;
  width: 21px;
  height: 21px;
  float: right;
  display: block;
}

#results_map_view {
  padding: 10px 0;
  margin: 0 -15px;
  color: #fff;
  background: #0054a6;
  position: relative;
  z-index: 99999999 !important;
}
#results_map_view h4 {
  color: #fff;
  margin: 3px 0 0 0;
  padding: 0;
  line-height: 1;
  font-size: 16px;
  font-size: 1rem;
}
@media (max-width: 991px) {
  #results_map_view h4 {
    margin: 5px 0 0 0;
  }
}
@media (max-width: 767px) {
  #results_map_view h4 {
    margin: 3px 0 0 0;
  }
}

.custom-search-input-2.map_view {
  background-color: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.custom-search-input-2.map_view input {
  border: none;
}
.custom-search-input-2.map_view input[type='submit'] {
  margin: 20px 0 0 0;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
}

.filters_listing.version_3 {
  margin: 0 -15px 0 -15px;
}
.filters_listing.version_3 ul {
  margin: 2px 0 0 0;
}
.filters_listing.version_3 ul li {
  margin-right: 10px;
}
.filters_listing.version_3 ul li:first-child {
  float: left;
}
.filters_listing.version_3 ul li:nth-child(2) {
  float: right;
  margin-right: 0;
}

a.btn_filt_map {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  padding: 8px 12px 8px 10px;
  line-height: 1;
  margin-bottom: 5px;
  display: inline-block;
  border: 1px solid rgba(0, 0, 0, 0.08);
  font-weight: 600;
  font-size: 12px;
  font-size: 0.75rem;
  color: rgba(0, 0, 0, 0.5);
  position: relative;
}
@media (max-width: 767px) {
  a.btn_filt_map {
    text-indent: -999px;
    display: block;
    width: 32px;
    height: 32px;
    padding: 0;
  }
}
a.btn_filt_map:hover {
  color: #fc5b62;
}
a.btn_filt_map:before {
  font-family: 'ElegantIcons';
  content: "\67";
  display: inline-block;
  margin-right: 5px;
  font-size: 13px;
  font-size: 0.8125rem;
}
@media (max-width: 767px) {
  a.btn_filt_map:before {
    text-indent: 1px;
    position: absolute;
    left: 3px;
    top: 4px;
    font-size: 21px;
    font-size: 1.3125rem;
    font-weight: normal;
  }
}

#filters {
  background-color: #fff;
  padding-top: 15px;
  border-bottom: 1px solid #ededed;
}
#filters h6 {
  margin-bottom: 15px;
  font-size: 16px;
  font-size: 1rem;
}

.map_view#filters {
  margin: 0 -15px;
}

.box_list.map_view {
  min-height: 120px;
  margin: 0 0 20px 0;
}
.box_list.map_view .wrapper {
  min-height: 125px;
  padding: 20px 20px 0 20px;
}
.box_list.map_view .wrapper .price {
  line-height: 1.2;
}
@media (max-width: 767px) {
  .box_list.map_view .wrapper h3 {
    font-size: 16px;
    font-size: 1rem;
  }
}
@media (max-width: 991px) {
  .box_list.map_view .wrapper {
    min-height: 110px;
    padding-right: 45px;
  }
}
@media (max-width: 991px) {
  .box_list.map_view .wrapper {
    min-height: 110px;
  }
}
.box_list.map_view figure {
  height: 185px;
  min-height: inherit;
}
.box_list.map_view figure small {
  font-size: 11px;
  font-size: 0.6875rem;
}
@media (max-width: 991px) {
  .box_list.map_view figure {
    height: 165px;
  }
}
.box_list.map_view figure a img {
  height: 210px;
  width: auto;
  max-width: none;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
@media (max-width: 991px) {
  .box_list.map_view figure a img {
    max-width: inherit;
    height: 210px;
    width: auto;
  }
}
.box_list.map_view figure a:hover img {
  -webkit-transform: translate(-50%, -50%) scale(1);
  -moz-transform: translate(-50%, -50%) scale(1);
  -ms-transform: translate(-50%, -50%) scale(1);
  -o-transform: translate(-50%, -50%) scale(1);
  transform: translate(-50%, -50%) scale(1);
}
.box_list.map_view ul {
  padding: 20px 15px 0 15px;
}
@media (max-width: 991px) {
  .box_list.map_view ul li:first-child {
    display: none;
  }
}

a.address {
  display: inline-block;
  font-weight: 500;
  color: #999;
  line-height: 1;
  font-size: 13px;
  font-size: 0.8125rem;
}
a.address:before {
  font-family: 'ElegantIcons';
  content: "\e081";
  margin-right: 5px;
  display: inline-block;
}
a.address:hover {
  color: #fc5b62;
}

.distance {
  margin-bottom: 15px;
}
.distance span {
  font-weight: 600;
  color: #fc5b62;
}

.rangeslider__handle {
  border: 2px solid #fc5b62 !important;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.15);
}

.rangeslider__fill {
  background: #fc5b62 !important;
}

/*-------- 2.15 Autocomplete Address Search --------*/
.pac-container {
  margin-top: 5px;
  border-top: none !important;
  -webkit-border-radius: 4px !important;
  -moz-border-radius: 4px !important;
  -ms-border-radius: 4px !important;
  border-radius: 4px !important;
  font-family: "Poppins", Helvetica, sans-serif;
  color: #555;
  -webkit-box-shadow: 0 12px 35px 2px rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0 12px 35px 2px rgba(0, 0, 0, 0.12);
  box-shadow: 0 12px 35px 2px rgba(0, 0, 0, 0.12);
}

.pac-item-query {
  color: #555;
}

/*-------- 2.16 OpenStreet Map --------*/
.leaflet-map-pane,
.leaflet-tile,
.leaflet-marker-icon,
.leaflet-marker-shadow,
.leaflet-tile-pane,
.leaflet-tile-container,
.leaflet-overlay-pane,
.leaflet-shadow-pane,
.leaflet-marker-pane,
.leaflet-popup-pane,
.leaflet-overlay-pane svg,
.leaflet-zoom-box,
.leaflet-image-layer,
.leaflet-layer {
  position: absolute;
  left: 0;
  top: 0;
}

.leaflet-container {
  overflow: hidden;
  -ms-touch-action: none;
  touch-action: none;
}

.leaflet-tile,
.leaflet-marker-icon,
.leaflet-marker-shadow {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
}

.leaflet-marker-icon,
.leaflet-marker-shadow {
  display: block;
}

/* map is broken in FF if you have max-width: 100% on tiles */
.leaflet-container img {
  max-width: none !important;
}

/* stupid Android 2 doesn't understand "max-width: none" properly */
.leaflet-container img.leaflet-image-layer {
  max-width: 15000px !important;
}

.leaflet-tile {
  filter: inherit;
  visibility: hidden;
}

.leaflet-tile-loaded {
  visibility: inherit;
}

.leaflet-zoom-box {
  width: 0;
  height: 0;
}

/* workaround for https://bugzilla.mozilla.org/show_bug.cgi?id=888319 */
.leaflet-overlay-pane svg {
  -moz-user-select: none;
}

.leaflet-tile-pane {
  z-index: 2;
}

.leaflet-objects-pane {
  z-index: 3;
}

.leaflet-overlay-pane {
  z-index: 4;
}

.leaflet-shadow-pane {
  z-index: 5;
}

.leaflet-marker-pane {
  z-index: 6;
}

.leaflet-popup-pane {
  z-index: 7;
}

.leaflet-vml-shape {
  width: 1px;
  height: 1px;
}

.lvml {
  behavior: url(#default#VML);
  display: inline-block;
  position: absolute;
}

/* control positioning */
.leaflet-control {
  position: relative;
  z-index: 7;
  pointer-events: auto;
}

.leaflet-top,
.leaflet-bottom {
  position: absolute;
  z-index: 1000;
  pointer-events: none;
}

.leaflet-top {
  top: 0;
}

.leaflet-right {
  right: 0;
}

.leaflet-bottom {
  bottom: 0;
}

.leaflet-left {
  left: 0;
}

.leaflet-control {
  float: left;
  clear: both;
}

.leaflet-right .leaflet-control {
  float: right;
}

.leaflet-top .leaflet-control {
  margin-top: 10px;
}

.leaflet-bottom .leaflet-control {
  margin-bottom: 10px;
}

.leaflet-left .leaflet-control {
  margin-left: 10px;
}

.leaflet-right .leaflet-control {
  margin-right: 10px;
}

/* zoom and fade animations */
.leaflet-fade-anim .leaflet-tile,
.leaflet-fade-anim .leaflet-popup {
  opacity: 0;
  -webkit-transition: opacity 0.2s linear;
  -moz-transition: opacity 0.2s linear;
  -o-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear;
}

.leaflet-fade-anim .leaflet-tile-loaded,
.leaflet-fade-anim .leaflet-map-pane .leaflet-popup {
  opacity: 1;
}

.leaflet-zoom-anim .leaflet-zoom-animated {
  -webkit-transition: -webkit-transform 0.25s cubic-bezier(0, 0, 0.25, 1);
  -moz-transition: -moz-transform 0.25s cubic-bezier(0, 0, 0.25, 1);
  -o-transition: -o-transform 0.25s cubic-bezier(0, 0, 0.25, 1);
  transition: transform 0.25s cubic-bezier(0, 0, 0.25, 1);
}

.leaflet-zoom-anim .leaflet-tile,
.leaflet-pan-anim .leaflet-tile,
.leaflet-touching .leaflet-zoom-animated {
  -webkit-transition: none;
  -moz-transition: none;
  -o-transition: none;
  transition: none;
}

.leaflet-zoom-anim .leaflet-zoom-hide {
  visibility: hidden;
}

/* cursors */
.leaflet-clickable {
  cursor: pointer;
}

.leaflet-container {
  cursor: -webkit-grab;
  cursor: -moz-grab;
}

.leaflet-popup-pane,
.leaflet-control {
  cursor: auto;
}

.leaflet-dragging .leaflet-container,
.leaflet-dragging .leaflet-clickable {
  cursor: move;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
}

/* visual tweaks */
.leaflet-container {
  background: #ddd;
  outline: 0;
}

.leaflet-container a {
  color: #0078A8;
}

.leaflet-container a.leaflet-active {
  outline: 2px solid orange;
}

.leaflet-zoom-box {
  border: 2px dotted #38f;
  background: rgba(255, 255, 255, 0.5);
}

/* general typography */
.leaflet-container {
  font: 12px/1.5 "Helvetica Neue", Arial, Helvetica, sans-serif;
}

/* general toolbar styles */
.leaflet-bar {
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.65);
  border-radius: 4px;
}

.leaflet-bar a,
.leaflet-bar a:hover {
  background-color: #fff;
  border-bottom: 1px solid #ccc;
  width: 26px;
  height: 26px;
  line-height: 26px;
  display: block;
  text-align: center;
  text-decoration: none;
  color: black;
}

.leaflet-bar a,
.leaflet-control-layers-toggle {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  display: block;
}

.leaflet-bar a:hover {
  background-color: #f4f4f4;
}

.leaflet-bar a:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.leaflet-bar a:last-child {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom: none;
}

.leaflet-bar a.leaflet-disabled {
  cursor: default;
  background-color: #f4f4f4;
  color: #bbb;
}

.leaflet-touch .leaflet-bar a {
  width: 30px;
  height: 30px;
  line-height: 30px;
}

/* zoom control */
.leaflet-control-zoom-in,
.leaflet-control-zoom-out {
  font: bold 18px 'Lucida Console', Monaco, monospace;
  text-indent: 1px;
}

.leaflet-control-zoom-out {
  font-size: 20px;
}

.leaflet-touch .leaflet-control-zoom-in {
  font-size: 22px;
}

.leaflet-touch .leaflet-control-zoom-out {
  font-size: 24px;
}

/* layers control */
.leaflet-control-layers {
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.4);
  background: #fff;
  border-radius: 5px;
}

.leaflet-control-layers-toggle {
  /* background-image: url(images/layers.png); */
  width: 36px;
  height: 36px;
}

.leaflet-retina .leaflet-control-layers-toggle {
  /* background-image: url(images/layers-2x.png); */
  background-size: 26px 26px;
}

.leaflet-touch .leaflet-control-layers-toggle {
  width: 44px;
  height: 44px;
}

.leaflet-control-layers .leaflet-control-layers-list,
.leaflet-control-layers-expanded .leaflet-control-layers-toggle {
  display: none;
}

.leaflet-control-layers-expanded .leaflet-control-layers-list {
  display: block;
  position: relative;
}

.leaflet-control-layers-expanded {
  padding: 6px 10px 6px 6px;
  color: #333;
  background: #fff;
}

.leaflet-control-layers-selector {
  margin-top: 2px;
  position: relative;
  top: 1px;
}

.leaflet-control-layers label {
  display: block;
}

.leaflet-control-layers-separator {
  height: 0;
  border-top: 1px solid #ddd;
  margin: 5px -10px 5px -6px;
}

/* attribution and scale controls */
.leaflet-container .leaflet-control-attribution {
  background: #fff;
  background: rgba(255, 255, 255, 0.7);
  margin: 0;
}

.leaflet-control-attribution,
.leaflet-control-scale-line {
  padding: 0 5px;
  color: #333;
}

.leaflet-control-attribution a {
  text-decoration: none;
}

.leaflet-control-attribution a:hover {
  text-decoration: underline;
}

.leaflet-container .leaflet-control-attribution,
.leaflet-container .leaflet-control-scale {
  font-size: 11px;
}

.leaflet-left .leaflet-control-scale {
  margin-left: 5px;
}

.leaflet-bottom .leaflet-control-scale {
  margin-bottom: 5px;
}

.leaflet-control-scale-line {
  border: 2px solid #777;
  border-top: none;
  line-height: 1.1;
  padding: 2px 5px 1px;
  font-size: 11px;
  white-space: nowrap;
  overflow: hidden;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  background: #fff;
  background: rgba(255, 255, 255, 0.5);
}

.leaflet-control-scale-line:not(:first-child) {
  border-top: 2px solid #777;
  border-bottom: none;
  margin-top: -2px;
}

.leaflet-control-scale-line:not(:first-child):not(:last-child) {
  border-bottom: 2px solid #777;
}

.leaflet-touch .leaflet-control-attribution,
.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
  box-shadow: none;
}

.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
  border: 2px solid rgba(0, 0, 0, 0.2);
  background-clip: padding-box;
}

/* popup */
.leaflet-popup {
  position: absolute;
  text-align: center;
}

.leaflet-popup-content-wrapper {
  padding: 1px;
  text-align: left;
  border-radius: 12px;
}

.leaflet-popup-content {
  margin: 13px 19px;
  line-height: 1.4;
}

.leaflet-popup-content p {
  margin: 18px 0;
}

.leaflet-popup-tip-container {
  margin: 0 auto;
  width: 40px;
  height: 20px;
  position: relative;
  overflow: hidden;
}

.leaflet-popup-tip {
  width: 17px;
  height: 17px;
  padding: 1px;
  margin: -10px auto 0;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.leaflet-popup-content-wrapper,
.leaflet-popup-tip {
  background: white;
  box-shadow: 0 3px 14px rgba(0, 0, 0, 0.4);
}

.leaflet-container a.leaflet-popup-close-button {
  position: absolute;
  top: 0;
  right: 0;
  padding: 4px 4px 0 0;
  text-align: center;
  width: 18px;
  height: 14px;
  font: 16px/14px Tahoma, Verdana, sans-serif;
  color: #c3c3c3;
  text-decoration: none;
  font-weight: bold;
  background: transparent;
}

.leaflet-container a.leaflet-popup-close-button:hover {
  color: #999;
}

.leaflet-popup-scrolled {
  overflow: auto;
  border-bottom: 1px solid #ddd;
  border-top: 1px solid #ddd;
}

.leaflet-oldie .leaflet-popup-content-wrapper {
  zoom: 1;
}

.leaflet-oldie .leaflet-popup-tip {
  width: 24px;
  margin: 0 auto;
  -ms-filter: "progid:DXImageTransform.Microsoft.Matrix(M11=0.70710678, M12=0.70710678, M21=-0.70710678, M22=0.70710678)";
  filter: progid:DXImageTransform.Microsoft.Matrix(M11=0.70710678, M12=0.70710678, M21=-0.70710678, M22=0.70710678);
}

.leaflet-oldie .leaflet-popup-tip-container {
  margin-top: -1px;
}

.leaflet-oldie .leaflet-control-zoom,
.leaflet-oldie .leaflet-control-layers,
.leaflet-oldie .leaflet-popup-content-wrapper,
.leaflet-oldie .leaflet-popup-tip {
  border: 1px solid #999;
}

/* div icon */
.leaflet-div-icon {
  background: #fff;
  border: 1px solid #666;
}

/* leaflet marker cluster styles ===================== */
.leaflet-cluster-anim .leaflet-marker-icon, .leaflet-cluster-anim .leaflet-marker-shadow {
  -webkit-transition: -webkit-transform 0.3s ease-out, opacity 0.3s ease-in;
  -moz-transition: -moz-transform 0.3s ease-out, opacity 0.3s ease-in;
  -o-transition: -o-transform 0.3s ease-out, opacity 0.3s ease-in;
  transition: transform 0.3s ease-out, opacity 0.3s ease-in;
}

.marker-cluster-small,
.marker-cluster-medium,
.marker-cluster-large {
  background-color: transparent;
}

.marker-cluster {
  background-clip: padding-box;
  border-radius: 20px;
}

.marker-cluster div {
  text-align: center;
  font-size: 15px;
  color: #fff;
  font-weight: bold;
  margin-left: 5px;
  margin-top: 5px;
  width: 36px;
  height: 36px;
  line-height: 36px;
  background-color: #fc5b62;
  position: relative;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
}

.marker-cluster-small div,
.marker-cluster-medium div,
.marker-cluster-large div {
  background-color: #fc5b62;
}

.marker-cluster div:before {
  content: '';
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  border: 7px solid #fc5b62;
  opacity: 0.2;
  box-shadow: inset 0 0 0 4px #fc5b62;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  animation: cluster-animation 2.5s infinite;
}

.marker-cluster span {
  font-family: Arial, sans-serif;
  line-height: 36px;
}

.leaflet-popup-content-wrapper {
  width: 240px;
  height: 270px;
  padding: 0;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.12);
  -webkit-border-radius: 5px !important;
  -moz-border-radius: 5px !important;
  -ms-border-radius: 5px !important;
  border-radius: 5px !important;
}

.leaflet-popup-content {
  margin: 0;
  font-family: "Poppins", Helvetica, sans-serif;
  position: relative;
}
.leaflet-popup-content img {
  display: block;
  -webkit-border-radius: 5px 5px 0 0;
  -moz-border-radius: 5px 5px 0 0;
  -ms-border-radius: 5px 5px 0 0;
  border-radius: 5px 5px 0 0;
}
.leaflet-popup-content h3 {
  font-size: 16px;
  font-size: 1rem;
  margin-bottom: 15px;
  line-height: 1.1;
  font-weight: 500;
  margin-top: 3px;
  color: #444;
}
.leaflet-popup-content em {
  display: inline-block;
  font-size: 12px;
  font-size: 0.75rem;
  color: #999;
  font-style: normal;
}
.leaflet-popup-content span {
  display: block;
  padding: 15px 20px 0 20px;
  font-size: 13px;
  font-size: 0.8125rem;
  line-height: 1.2;
  color: #fff;
  position: relative;
}
.leaflet-popup-content span strong {
  display: block;
  font-weight: 500;
}
.leaflet-popup-content a {
  color: #fc5b62;
}
.leaflet-popup-content span.infobox_rate {
  display: inline-block;
  margin: -39px 0 0 -20px;
  float: left;
  background-color: #0054a6;
  padding: 5px 8px;
  font-weight: 500;
  text-align: left;
  font-size: 12px;
  font-size: 0.75rem;
}

.leaflet-container a.leaflet-popup-close-button {
  z-index: 9999;
  background-color: #fff;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  top: 5px;
  right: 5px;
  padding: 0;
  line-height: 18px;
  text-align: center;
}

/*-------- 2.17 Parallax Video Background --------*/
.hero_single.jarallax {
  height: 650px;
}
.hero_single.jarallax .wrapper h3 {
  font-size: 42px;
  font-size: 2.625rem;
  text-shadow: 4px 4px 12px rgba(0, 0, 0, 0.3);
}
@media (max-width: 767px) {
  .hero_single.jarallax .wrapper h3 {
    font-size: 30px;
    font-size: 1.875rem;
    margin-top: 60px;
  }
}
.hero_single.jarallax .wrapper p {
  font-weight: 400;
  margin: 5px 0 0 0;
  padding: 0;
  font-size: 21px;
  font-size: 1.3125rem;
  text-shadow: 4px 4px 12px rgba(0, 0, 0, 0.3);
}
@media (max-width: 767px) {
  .hero_single.jarallax .wrapper p {
    padding: 0;
    font-size: 16px;
    font-size: 1rem;
  }
}
.hero_single.jarallax video {
  opacity: 1;
}

/*-------- 2.18 Other Headers --------*/
.hero_single.fullscreen {
  width: 100%;
  height: 100vh;
}
@media (max-width: 767px) {
  .hero_single.fullscreen {
    height: 90vh;
  }
}
.hero_single.fullscreen.video_bg video {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  opacity: 1;
  z-index: -1;
}

a.btn_explore {
  background: #fff;
  display: block;
  width: 60px;
  height: 60px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  line-height: 65px;
  text-align: center;
  position: absolute;
  bottom: 40px;
  left: 50%;
  margin-left: -30px;
  font-size: 24px;
  font-size: 1.5rem;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 0 0 rgba(255, 255, 255, 0.4);
  animation: pulseanime 2.5s infinite;
  -webkit-animation: pulseanime 2.5s infinite;
}
a.btn_explore:hover {
  bottom: 30px;
}

@-webkit-keyframes pulseanime {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
  }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}
@keyframes pulseanime {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
  }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}
/*-------- 2.19 Home Categories Hover effect --------*/
.hero_single.cat_hover {
  height: 720px;
  background-color: #00509e;
  background-color: rgba(0, 84, 166, 0.7);
}
.hero_single.cat_hover .wrapper {
  padding-top: 45px;
  background: #0054a6;
  background: -webkit-linear-gradient(top, #0054a6, transparent);
  background: linear-gradient(to bottom, #0054a6, transparent);
}
.hero_single.cat_hover .wrapper h3 {
  font-size: 42px;
  font-size: 2.625rem;
}
@media (max-width: 767px) {
  .hero_single.cat_hover .wrapper h3 {
    font-size: 30px;
    font-size: 1.875rem;
    margin-top: 60px;
  }
}
.hero_single.cat_hover .cat_nav_hover ul {
  list-style: none;
  padding: 0;
  margin: 30px 0 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 767px) {
  .hero_single.cat_hover .cat_nav_hover ul {
    overflow-x: scroll;
    justify-content: start;
  }
}
.hero_single.cat_hover .cat_nav_hover ul li {
  padding: 0 10px;
  width: calc(20% - 10px);
}
@media (max-width: 767px) {
  .hero_single.cat_hover .cat_nav_hover ul li {
    width: auto;
  }
}
.hero_single.cat_hover .cat_nav_hover ul li a {
  display: block;
}
.hero_single.cat_hover .cat_nav_hover ul li a figure {
  width: 100%;
  height: 140px;
  border: 3px solid rgba(255, 255, 255, 0.8);
  border-radius: 10px;
  overflow: hidden;
  display: block;
}
@media (max-width: 767px) {
  .hero_single.cat_hover .cat_nav_hover ul li a figure {
    height: 110px;
  }
}
.hero_single.cat_hover .cat_nav_hover ul li a figure img {
  margin: 5px;
  width: calc(100% - 10px);
  height: calc(100% - 10px);
  object-fit: cover;
  object-position: 50% 50%;
  border-radius: 5px;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  opacity: 0.8;
}
.hero_single.cat_hover .cat_nav_hover ul li a h2 {
  color: #fff;
  font-size: 18px;
  font-size: 1.125rem;
  font-weight: 400;
}
.hero_single.cat_hover .cat_nav_hover ul li a:hover figure img {
  opacity: 1;
}
.hero_single.cat_hover .image-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0;
  visibility: hidden;
  transition: all 1s ease-in-out;
}
.hero_single.cat_hover .image-bg img, .hero_single.cat_hover .image-bg video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
  filter: grayscale(100%);
}
@media (max-width: 767px) {
  .hero_single.cat_hover .container-item {
    width: 200px;
  }
}
.hero_single.cat_hover .container-item.active figure img {
  opacity: 1;
}
.hero_single.cat_hover .container-item.active a + .image-bg {
  opacity: 1;
  visibility: visible;
  overflow: hidden;
  width: 100%;
  height: 100%;
}
.hero_single.cat_hover.version_2 {
  height: 100vh;
  background: none;
  background-color: black;
  background-color: rgba(0, 0, 0, 0.6);
}
@media (max-width: 767px) {
  .hero_single.cat_hover.version_2 {
    height: 90vh;
  }
}
.hero_single.cat_hover.version_2 .wrapper {
  background: transparent;
  background: -webkit-linear-gradient(top, transparent, transparent);
  background: linear-gradient(to bottom, transparent, transparent);
}
.hero_single.cat_hover.version_2 .image-bg img, .hero_single.cat_hover.version_2 .image-bg video {
  filter: grayscale(0);
}

/*-------- 2.20 Home Search Radio categories --------*/
.hero_single.radio_cat {
  height: 720px;
}
.hero_single.radio_cat .switch-field {
  padding: 45px 0 15px 0;
  justify-content: center;
  display: flex;
  border: 0;
}
@media (max-width: 767px) {
  .hero_single.radio_cat .switch-field {
    overflow-x: scroll;
    justify-content: start;
  }
}
.hero_single.radio_cat .switch-field input:checked + label {
  background-color: black;
  background-color: rgba(0, 0, 0, 0.7);
}
.hero_single.radio_cat .switch-field label {
  margin: 0 5px;
  display: inline-block;
  font-weight: 500;
  font-size: 14px;
  font-size: 0.875rem;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  padding: 15px;
  color: #fff;
  font-weight: 500;
  background-color: black;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 3px;
  min-width: 125px;
}
.hero_single.radio_cat .switch-field label img {
  display: block;
  width: 45px;
  height: auto;
  margin: 0 auto 10px;
}
@media (max-width: 767px) {
  .hero_single.radio_cat .switch-field label {
    padding: 10px;
  }
}
.hero_single.radio_cat .switch-field label:hover {
  background-color: black;
  background-color: rgba(0, 0, 0, 0.7);
}

/*============================================================================================*/
/* 3.  COMMON */
/*============================================================================================*/
/*-------- 3.1 Misc --------*/
@media (min-width: 1400px) {
  .container-custom {
    max-width: 1600px;
    padding-left: 60px;
    padding-right: 60px;
  }
}
.opacity-mask {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
}

.form-control {
  padding: 10px;
  height: 42px;
  font-size: 14px;
  font-size: 0.875rem;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  border: 1px solid #d2d8dd;
  color: #495057;
}

.form-control:disabled, .form-control[readonly] {
  background-color: #fff;
  opacity: 1;
}

.form-group {
  position: relative;
  margin-bottom: 1rem;
}

.bg_color_1 {
  background-color: #fff;
}

.box_1 {
  background-color: #121921;
  color: #fff;
  padding: 45px;
  opacity: 0;
}
.box_1 p {
  opacity: 0.8;
}
.box_1 h3 {
  color: #fff;
}

.animated .box_1 {
  animation: color 0.3s ease-in-out;
  animation-delay: 0.3s;
  -webkit-animation-delay: 0.3s;
  -moz-animation-delay: 0.3s;
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  opacity: 0;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
}

.call_section {
  background: url(../img/bg_call_section.jpg) center center no-repeat fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  min-height: 400px;
  padding: 10% 0;
}

.box_faq {
  position: relative;
  padding-left: 50px;
}
.box_faq h4 {
  font-size: 18px;
  font-size: 1.125rem;
}
.box_faq i {
  font-size: 36px;
  font-size: 2.25rem;
  position: absolute;
  left: 0;
  top: 0;
  color: #999;
}

.custom-select-form .nice-select {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  border: 1px solid #d2d8dd;
  height: 45px;
  line-height: 42px;
}
.custom-select-form .nice-select:hover {
  border-color: #d2d8dd;
}
.custom-select-form .nice-select:active, .custom-select-form .nice-select.open, .custom-select-form .nice-select:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-select-form select {
  display: none;
}

ul.share-buttons {
  padding: 0;
  list-style: none;
  text-align: center;
  margin: 0 0 25px 0;
}
ul.share-buttons li {
  display: inline-block;
  margin: 0 5px 5px 5px;
}
ul.share-buttons li a {
  background: #fff;
  border: 1px solid #666;
  border-radius: 50px;
  font-weight: 500;
  font-size: 13px;
  padding: 7px 20px;
  transition: 0.3s;
  display: inline-block;
  line-height: 17px;
  font-weight: 500;
}
ul.share-buttons li a:hover {
  color: #fff;
}
ul.share-buttons li a.fb-share {
  border-color: #3b5998;
  color: #3b5998;
}
ul.share-buttons li a.fb-share:hover {
  background: #3b5998;
  color: #fff;
}
ul.share-buttons li a.gplus-share {
  border-color: #dd4b39;
  color: #dd4b39;
}
ul.share-buttons li a.gplus-share:hover {
  background: #dd4b39;
  color: #fff;
}
ul.share-buttons li a.twitter-share {
  border-color: #1da1f2;
  color: #1da1f2;
}
ul.share-buttons li a.twitter-share:hover {
  background: #1da1f2;
  color: #fff;
}
ul.share-buttons li i {
  font-size: 16px;
  font-size: 1rem;
  position: relative;
  right: 3px;
  top: 2px;
}

/* Jquery select */
.nice-select {
  -webkit-tap-highlight-color: transparent;
  background-color: #fff;
  border-radius: 3px;
  border: none;
  box-sizing: border-box;
  clear: both;
  cursor: pointer;
  display: block;
  float: left;
  font-family: inherit;
  font-size: 14px;
  font-weight: normal;
  height: 50px;
  line-height: 48px;
  outline: none;
  padding-left: 15px;
  padding-right: 27px;
  position: relative;
  text-align: left !important;
  transition: all 0.2s ease-in-out;
  user-select: none;
  white-space: nowrap;
  width: auto;
  color: #555;
  padding-top: 2px;
}
.nice-select:hover {
  border-color: #8c8c8c;
}
.nice-select:active, .nice-select.open, .nice-select:focus {
  border-color: #999;
}
.nice-select:after {
  border-bottom: 2px solid #999;
  border-right: 2px solid #999;
  content: '';
  display: block;
  height: 8px;
  margin-top: -5px;
  pointer-events: none;
  position: absolute;
  right: 20px;
  top: 50%;
  transform-origin: 66% 66%;
  transform: rotate(45deg);
  transition: all 0.15s ease-in-out;
  width: 8px;
}
.nice-select.open:after {
  transform: rotate(-135deg);
}
.nice-select.open .list {
  opacity: 1;
  pointer-events: auto;
  transform: scale(1) translateY(0);
}
.nice-select.disabled {
  border-color: #9e9e9e;
  color: #999;
  pointer-events: none;
}
.nice-select.disabled:after {
  border-color: #cccccc;
}
.nice-select.wide {
  width: 100%;
}
.nice-select.wide .list {
  left: 0 !important;
  right: 0 !important;
}
.nice-select.right {
  float: right;
}
.nice-select.right .list {
  left: auto;
  right: 0;
}
.nice-select.small {
  font-size: 12px;
  height: 36px;
  line-height: 34px;
}
.nice-select.small:after {
  height: 4px;
  width: 4px;
}
.nice-select.small .option {
  line-height: 34px;
  min-height: 34px;
}
.nice-select .list {
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0 0 0 1px rgba(68, 68, 68, 0.11);
  box-sizing: border-box;
  margin-top: 4px;
  opacity: 0;
  overflow: hidden;
  padding: 0;
  pointer-events: none;
  position: absolute;
  top: 100%;
  left: 0;
  transform-origin: 50% 0;
  transform: scale(0.75) translateY(-25px);
  transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25), opacity 0.15s ease-out;
  z-index: 9;
  height: 18vh;
  overflow: auto;
}
.nice-select .list:hover .option:not(:hover) {
  background-color: transparent !important;
}
.nice-select .list::-webkit-scrollbar {
  width: 14px;
  height: 18px;
}
.nice-select .list::-webkit-scrollbar-thumb {
  height: 6px;
  border: 4px solid transparent;
  background-clip: padding-box;
  -webkit-border-radius: 7px;
  background-color: rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05);
}
.nice-select .list::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  display: none;
}
.nice-select .list::-webkit-scrollbar-corner {
  background-color: transparent;
}
.nice-select .option {
  cursor: pointer;
  font-weight: 400;
  line-height: 38px;
  list-style: none;
  min-height: 38px;
  outline: none;
  padding-left: 15px;
  padding-right: 26px;
  text-align: left;
  transition: all 0.2s;
}
.nice-select .option:hover, .nice-select .option.focus, .nice-select .option.selected.focus {
  background-color: #f6f6f6;
}
.nice-select .option.selected {
  font-weight: 500;
}
.nice-select .option.disabled {
  background-color: transparent;
  color: #999;
  cursor: default;
}

.no-csspointerevents .nice-select .list {
  display: none;
}
.no-csspointerevents .nice-select.open .list {
  display: block;
}

/* Scroll top button */
#toTop {
  position: fixed;
  right: 25px;
  bottom: 25px;
  z-index: 9999;
  width: 46px;
  height: 46px;
  background-color: rgba(0, 0, 0, 0.6);
  opacity: 1;
  border-radius: 50%;
  text-align: center;
  font-size: 21px;
  color: #fff;
  cursor: pointer;
}
#toTop:after {
  content: "\e899";
  font-family: "fontello";
  position: relative;
  display: block;
  top: 50%;
  -webkit-transform: translateY(-55%);
  transform: translateY(-55%);
}
#toTop:hover {
  background-color: #fc5b62;
}

/* Bootstrap Modal Fix */
.modal-backdrop {
  position: static;
  background-color: transparent;
}

.modal {
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.7);
}

/*-------- 3.2 Accordion --------*/
.accordion_2 .card {
  border: 0;
  margin-bottom: 5px;
  -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
}
.accordion_2 .card .card-header {
  background-color: #fff;
  border-bottom: 1px solid #ededed;
  padding: 20px;
}
.accordion_2 .card-body {
  padding-bottom: 0;
}
.accordion_2 .card-header h5 {
  font-size: 16px;
  font-size: 1rem;
}
.accordion_2 .card-header h5 a {
  border: 0;
  display: block;
  color: #999;
}
.accordion_2 .card-header h5 a i.indicator {
  font-size: 18px;
  font-size: 1.125rem;
  font-weight: normal;
  float: right;
  color: #fc5b62;
}

.card {
  border-radius: 0;
  border: 0;
  margin-bottom: 5px;
}

/*-------- 3.3 List --------*/
ul.list_ok {
  list-style: none;
  margin: 25px 0 30px 0;
  padding: 0;
}
ul.list_ok li {
  position: relative;
  padding-left: 45px;
  margin-bottom: 20px;
}
ul.list_ok li:before {
  font-family: 'ElegantIcons';
  content: "\52";
  position: absolute;
  left: 0;
  top: -10px;
  font-size: 32px;
  font-size: 2rem;
  color: #8dc63f;
}

ul.bullets {
  line-height: 1.8;
  margin: 0;
  padding: 0;
}
ul.bullets li {
  position: relative;
  padding-left: 20px;
}
ul.bullets li:before {
  font-style: normal;
  font-weight: normal;
  font-family: 'ElegantIcons';
  font-size: 14px;
  font-size: 0.875rem;
  content: "\5c";
  color: #ccc;
  position: absolute;
  left: 0;
  top: 0;
}

/*-------- 3.4 Spacing --------*/
.add_bottom_15 {
  margin-bottom: 15px;
}

.add_bottom_30 {
  margin-bottom: 30px;
}

.add_bottom_45 {
  margin-bottom: 45px;
}

.add_bottom_60 {
  margin-bottom: 60px;
}

.add_bottom_75 {
  margin-bottom: 75px;
}

.add_top_10 {
  margin-top: 10px;
}

.add_top_15 {
  margin-top: 15px;
}

.add_top_20 {
  margin-top: 20px;
}

.add_top_30 {
  margin-top: 30px;
}

.add_top_60 {
  margin-top: 60px;
}

.more_padding_left {
  padding-left: 40px;
}

.nomargin_top {
  margin-top: 0;
}

.nopadding {
  margin: 0 !important;
  padding: 0 !important;
}

.nomargin {
  margin: 0 !important;
}

.margin_60 {
  padding-top: 60px;
  padding-bottom: 60px;
}

.margin_60_35 {
  padding-top: 60px;
  padding-bottom: 35px;
}
@media (max-width: 575px) {
  .margin_60_35 {
    padding-top: 30px;
    padding-bottom: 5px;
  }
}

.margin_80 {
  padding-top: 80px;
  padding-bottom: 80px;
}
@media (max-width: 991px) {
  .margin_80 {
    padding-bottom: 60px;
    padding-top: 60px;
  }
}
@media (max-width: 575px) {
  .margin_80 {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

.margin_80_55 {
  padding-top: 80px;
  padding-bottom: 55px;
}
@media (max-width: 991px) {
  .margin_80_55 {
    padding-top: 60px;
    padding-bottom: 35px;
  }
}
@media (max-width: 575px) {
  .margin_80_55 {
    padding-top: 45px;
    padding-bottom: 15px;
  }
}

.margin_80_0 {
  padding-top: 80px;
}
@media (max-width: 991px) {
  .margin_80_0 {
    padding-top: 60px;
  }
}
@media (max-width: 575px) {
  .margin_80_0 {
    padding-top: 45px;
  }
}

.margin_60_0 {
  padding-top: 60px;
}
@media (max-width: 991px) {
  .margin_60_0 {
    padding-top: 60px;
  }
}
@media (max-width: 575px) {
  .margin_60_0 {
    padding-top: 45px;
  }
}

.margin_30_95 {
  padding-top: 30px;
  padding-bottom: 95px;
}
@media (max-width: 991px) {
  .margin_30_95 {
    padding-top: 15px;
    padding-bottom: 35px;
  }
}
@media (max-width: 575px) {
  .margin_30_95 {
    padding-top: 5px;
    padding-bottom: 30px;
  }
}

@media (max-width: 991px) {
  .hidden_tablet {
    display: none !important;
  }
}

@media (max-width: 767px) {
  .hidden_mobile {
    display: none !important;
  }
}

/*-------- 3.5 Block reveal --------*/
.animated .block-reveal {
  height: auto;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.block-horizzontal, .block-vertical {
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 10;
}

.animated .block-horizzontal {
  animation: reveal_left_to_right 1s ease-in-out;
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  background: #0054a6;
}
.animated .block-vertical {
  animation: reveal_top_to_bottom 1s ease-in-out;
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  background: #0054a6;
}

.block-reveal img {
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  opacity: 0;
}

.animated .block-reveal img {
  animation: color 0.5s ease-in-out;
  animation-delay: 0.5s;
  -webkit-animation-delay: 0.5s;
  -moz-animation-delay: 0.5s;
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  opacity: 0;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
}

@keyframes reveal_left_to_right {
  0% {
    -webkit-transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    -o-transform: translateX(-100%);
    transform: translateX(-100%);
  }
  50% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(100%);
    -moz-transform: translateX(100%);
    -ms-transform: translateX(100%);
    -o-transform: translateX(100%);
    transform: translateX(100%);
  }
}
@keyframes reveal_top_to_bottom {
  0% {
    -webkit-transform: translateY(-100%);
    -moz-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    -o-transform: translateY(-100%);
    transform: translateY(-100%);
  }
  50% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(100%);
    -moz-transform: translateY(100%);
    -ms-transform: translateY(100%);
    -o-transform: translateY(100%);
    transform: translateY(100%);
  }
}
@keyframes color {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/*-------- 3.6 Cookie bar --------*/
#cookie-bar {
  background: rgba(0, 0, 0, 0.8);
  height: auto;
  line-height: 24px;
  color: #eeeeee;
  text-align: center;
  padding: 15px 0;
  font-weight: 400;
  font-size: 13px;
  font-size: 0.8125rem;
  font-weight: 500;
}
#cookie-bar p {
  margin: 0;
  padding: 0;
}
#cookie-bar a {
  color: #fff;
  display: inline-block;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  text-decoration: none;
  padding: 2px 10px;
  margin-left: 8px;
}
@media (max-width: 767px) {
  #cookie-bar a {
    display: block;
    max-width: 150px;
    margin: auto;
    margin-bottom: 5px;
  }
}
#cookie-bar .cb-enable {
  background: #32a067;
}
#cookie-bar .cb-enable:hover {
  background: #fff;
  color: #0054a6;
}
#cookie-bar .cb-disable {
  background: #990000;
}
#cookie-bar .cb-disable:hover {
  background: #bb0000;
}
#cookie-bar .cb-policy {
  background: #fc5b62;
}
#cookie-bar .cb-policy:hover {
  background: #fff;
  color: #0054a6;
}
#cookie-bar.fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999999;
}
#cookie-bar.fixed.bottom {
  bottom: 0;
  top: auto;
}
@media (max-width: 767px) {
  #cookie-bar {
    padding: 5px 0;
  }
}

/*-------- 3.7 Sing In Modal --------*/
#sign-in-dialog {
  background: #fff;
  padding: 30px;
  padding-top: 0;
  text-align: left;
  max-width: 400px;
  margin: 40px auto;
  position: relative;
  box-sizing: border-box;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
}

/* Popup close button*/
#sign-in-dialog .mfp-close,
#lang-dialog .mfp-close {
  color: #666;
  background-color: #e4e4e4;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  top: 12px;
  right: 20px;
  width: 32px;
  height: 32px;
  line-height: 32px;
}

#sign-in-dialog .mfp-close:hover,
#lang-dialog .mfp-close:hover {
  color: #fff;
  background-color: #66676b;
}

#sign-in-dialog .mfp-close:before {
  font-size: 24px;
  font-family: 'ElegantIcons';
  content: "\4d";
}

.small-dialog-header {
  font-size: 18px;
  width: calc(100% + 60px);
  position: relative;
  left: -30px;
  top: 0;
  -webkit-border-radius: 4px 4px;
  -moz-border-radius: 4px 4px;
  -ms-border-radius: 4px 4px;
  border-radius: 4px 4px;
  display: inline-block;
  background-color: #f6f6f6;
  padding: 18px 20px 15px 20px;
  margin-bottom: 30px;
}
.small-dialog-header h3 {
  padding: 0;
  margin: 0;
  font-size: 18px;
  font-size: 1.125rem;
  font-weight: 500;
}

#sign-in-dialog .form-group {
  position: relative;
}

#sign-in-dialog .form-group input.form-control {
  padding-left: 40px;
}

#sign-in-dialog .form-group i {
  font-size: 21px;
  font-size: 1.3125rem;
  position: absolute;
  left: 12px;
  top: 34px;
  color: #ccc;
  width: 25px;
  height: 25px;
  display: block;
  font-weight: 400 !important;
}

#sign-in-dialog .form-group i.icon_lock_alt {
  top: 36px;
}

.my-toggle {
  background: transparent;
  border: 0;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  color: #888;
  cursor: pointer;
  font-size: 10px;
  font-size: 10px;
  font-size: 0.625rem;
  font-weight: bold;
  margin-right: 5px;
  height: 30px;
  line-height: 30px;
  padding: 0 10px;
  text-transform: uppercase;
  -moz-appearance: none;
  -webkit-appearance: none;
  background-color: #fff;
}
.my-toggle:hover, .my-toggle:focus {
  background-color: #eee;
  color: #555;
  outline: transparent;
}

.hideShowPassword-wrapper {
  width: 100% !important;
}

.checkboxes label {
  color: #999;
}

a#forgot {
  color: #999;
  font-weight: 500;
  font-size: 13px;
  font-size: 0.8125rem;
}

#forgot_pw {
  background-color: #fff;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 99;
  min-height: 450px;
  display: none;
}
#forgot_pw label {
  font-weight: 500;
}

.sign-in-wrapper {
  position: relative;
  height: 100%;
}

a.social_bt {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  text-align: center;
  color: #fff;
  min-width: 200px;
  margin-bottom: 15px;
  display: block;
  padding: 12px;
  line-height: 1;
  position: relative;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}
a.social_bt:hover {
  -webkit-filter: brightness(115%);
  filter: brightness(115%);
}

a.social_bt.facebook:before,
a.social_bt.google:before,
a.social_bt.linkedin:before {
  font-family: 'ElegantIcons';
  position: absolute;
  left: 12px;
  top: 10px;
  font-size: 1rem;
  color: #fff;
}

a.social_bt.facebook {
  background-color: #3B5998;
}
a.social_bt.facebook:before {
  content: "\e093";
}

a.social_bt.google {
  background-color: #DC4E41;
}
a.social_bt.google:before {
  content: "\e096";
  top: 12px;
}

a#forgot {
  position: relative;
  top: -3px;
}

.divider {
  text-align: center;
  height: 1px;
  margin: 30px 0 20px 0;
  background-color: #e1e8ed;
}
.divider span {
  position: relative;
  top: -20px;
  background-color: #fff;
  display: inline-block;
  padding: 10px;
  font-style: italic;
}

/* Magnific popup */
.mfp-bottom-bar {
  padding: 0 10px !important;
}

.mfp-counter {
  right: 10px !important;
  color: #fff !important;
}

.mfp-bg {
  opacity: 0.8 !important;
}

/*-------- 3.8 Input Switcher --------*/
.switchery {
  background-color: #fff;
  border: 1px solid #dfdfdf;
  border-radius: 20px;
  cursor: pointer;
  display: inline-block;
  height: 30px;
  position: relative;
  vertical-align: middle;
  width: 50px;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  box-sizing: content-box;
  background-clip: content-box;
  float: right;
}
.switchery > small {
  background: #fff;
  border-radius: 100%;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
  height: 30px;
  position: absolute;
  top: 0;
  width: 30px;
}

/* Switchery sizes. */
.switchery-small {
  border-radius: 20px;
  height: 20px;
  width: 33px;
}
.switchery-small > small {
  height: 20px;
  width: 20px;
}

.switchery-large {
  border-radius: 40px;
  height: 40px;
  width: 66px;
}
.switchery-large > small {
  height: 40px;
  width: 40px;
}

/*-------- 3.9 Datepicker --------*/
.daterangepicker {
  font-family: "Poppins", Helvetica, sans-serif !important;
}

/* Fix for scroll issue */
@media screen and (min-width: 560px) {
  .daterangepicker {
    width: 490px !important;
  }

  .daterangepicker.single {
    width: auto !important;
  }
}
.applyBtn.btn.btn-sm.btn-primary {
  background-color: #fc5b62;
  border: 0;
}

.drp-selected {
  display: none !important;
}

.daterangepicker td.active, .daterangepicker td.active:hover {
  background-color: #0054a6 !important;
}

@media screen and (max-width: 600px) {
  .daterangepicker .drp-calendar {
    max-width: 100% !important;
  }

  .daterangepicker .drp-calendar.left, .daterangepicker .drp-calendar.right {
    clear: none !important;
    float: none !important;
    margin-right: 0;
  }
}
.daterangepicker {
  -webkit-box-shadow: 0 12px 35px 2px rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0 12px 35px 2px rgba(0, 0, 0, 0.12);
  box-shadow: 0 12px 35px 2px rgba(0, 0, 0, 0.12);
}

/*-------- 3.10 Modal Generic --------*/
#modal-dialog {
  background: #fff;
  padding: 30px;
  padding-top: 0;
  text-align: left;
  max-width: 450px;
  margin: 40px auto;
  position: relative;
  box-sizing: border-box;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
}

/* Popup close button*/
#modal-dialog .mfp-close {
  color: #666;
  background-color: #e4e4e4;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  top: 12px;
  right: 20px;
  width: 32px;
  height: 32px;
  line-height: 32px;
}

#modal-dialog .mfp-close:hover {
  color: #fff;
  background-color: #66676b;
}

#modal-dialog .mfp-close:before {
  font-size: 24px;
  font-family: 'ElegantIcons';
  content: "\4d";
}

/*-------- 3.11 Others pricing tables --------*/
.margin_tabs {
  margin-top: -97px;
}
@media (max-width: 991px) {
  .margin_tabs {
    margin-top: -68px;
  }
}
@media (max-width: 767px) {
  .margin_tabs {
    margin-top: -50px;
  }
}

.tabs {
  position: relative;
  width: 100%;
  overflow: visible;
  padding-bottom: 35px;
  z-index: 5;
}
.tabs nav {
  text-align: center;
  overflow: hidden;
}
.tabs nav ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: inline-block;
  width: 100%;
  text-align: center;
}
.tabs nav ul li {
  margin: 0 -4px 0 0;
  display: block;
  display: inline-block;
  background-color: #ededed;
  width: 33%;
  text-align: left;
  line-height: 1.3;
}
@media (max-width: 991px) {
  .tabs nav ul li {
    text-align: center;
  }
}
.tabs nav ul li a {
  padding: 25px 35px 25px 85px;
  color: #555;
  display: block;
  font-size: 21px;
  font-size: 1.3125rem;
  white-space: nowrap;
  position: relative;
  font-weight: 500;
}
@media (max-width: 991px) {
  .tabs nav ul li a {
    padding: 15px;
    font-size: 16px;
    font-size: 1rem;
  }
}
.tabs nav ul li a i {
  position: absolute;
  left: 35px;
  top: 25px;
  font-size: 38px;
  font-size: 2.375rem;
}
@media (max-width: 991px) {
  .tabs nav ul li a i {
    display: none;
  }
}
.tabs nav ul li a em {
  display: block;
  font-size: 16px;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
}
@media (max-width: 991px) {
  .tabs nav ul li a em {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media (max-width: 767px) {
  .tabs nav ul li a em {
    display: none;
  }
}
.tabs nav ul li:first-child {
  -webkit-border-radius: 3px 0 0 0;
  -moz-border-radius: 3px 0 0 0;
  -ms-border-radius: 3px 0 0 0;
  border-radius: 3px 0 0 0;
  border-right: 2px solid #ddd;
}
.tabs nav ul li:last-child {
  -webkit-border-radius: 0 3px 0 0;
  -moz-border-radius: 0 3px 0 0;
  -ms-border-radius: 0 3px 0 0;
  border-radius: 0 3px 0 0;
  border-left: 2px solid #ddd;
}
.tabs nav ul li.tab-current {
  background-color: #fafafc;
  z-index: 100;
}
.tabs nav ul li.tab-current:after {
  right: auto;
  left: 100%;
  width: 4000px;
}
.tabs nav ul li.tab-current a {
  color: #222;
}
.tabs nav ul li.tab-current a i {
  color: #fc5b62;
}

/* Content */
.content section {
  padding: 30px 0 0 0;
  display: none;
  max-width: 1230px;
  margin: 0 auto;
}
.content section:before, .content section:after {
  content: '';
  display: table;
}
.content section.content-current {
  display: block;
}

.content section:after {
  clear: both;
}

.no-js .content section {
  display: block;
  padding-bottom: 2em;
  border-bottom: 1px solid #e2e2e2;
}

.box_pricing {
  padding: 30px;
  margin-bottom: 30px;
  border: 1px solid #d2d8dd;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  position: relative;
}
@media (max-width: 767px) {
  .box_pricing {
    padding: 15px;
  }
}
.box_pricing hr {
  margin: 25px -30px;
  border-color: #d2d8dd;
}
@media (max-width: 767px) {
  .box_pricing hr {
    margin: 25px -15px;
  }
}
.box_pricing ul {
  list-style: disc;
  padding: 0 0 0 20px;
  margin: 0 0 30px 0;
}
.box_pricing a {
  background-color: #fc5b62;
  display: block;
  color: #fff;
  padding: 10px;
  font-weight: 500;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  text-align: center;
}
.box_pricing a:hover {
  background-color: #0054a6;
}
.box_pricing .price {
  text-align: center;
  font-weight: 500;
  font-size: 36px;
  font-size: 2.25rem;
  margin-bottom: 20px;
  color: #fc5b62;
}
.box_pricing .price sup {
  font-size: 16px;
  font-size: 1rem;
}
.box_pricing .price em {
  font-size: 16px;
  font-size: 1rem;
  color: #555;
  font-weight: 400;
}

.ribbon_tabs {
  position: absolute;
  right: -5px;
  top: -5px;
  z-index: 1;
  overflow: hidden;
  width: 75px;
  height: 75px;
  text-align: right;
}
.ribbon_tabs span {
  font-size: 10px;
  font-weight: bold;
  color: #222;
  text-transform: uppercase;
  text-align: center;
  line-height: 20px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  width: 100px;
  display: block;
  position: absolute;
  top: 19px;
  right: -21px;
}
.ribbon_tabs span.top_selling {
  background: #00dfaa;
}
.ribbon_tabs span.top_selling::before {
  content: "";
  position: absolute;
  left: 0px;
  top: 100%;
  z-index: -1;
  border-left: 3px solid #008a69;
  border-right: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #008a69;
}
.ribbon_tabs span.top_selling::after {
  content: "";
  position: absolute;
  right: 0px;
  top: 100%;
  z-index: -1;
  border-left: 3px solid transparent;
  border-right: 3px solid #008a69;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #008a69;
}

/*Pricing tables*/
.plans {
  margin: 0px auto 20px;
}

.plans:before,
.plans:after {
  content: '';
  display: table;
}

.plans:after {
  clear: both;
}

.plan {
  margin: 10px 0;
  padding: 20px 20px 30px 20px;
  text-align: center;
  border: solid #d2d8dd;
  border-width: 1px 0 1px 1px;
  background-color: #fff;
  -webkit-box-shadow: 0 2px 4px rgba(3, 27, 78, 0.06);
  -moz-box-shadow: 0 2px 4px rgba(3, 27, 78, 0.06);
  box-shadow: 0 2px 4px rgba(3, 27, 78, 0.06);
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  overflow: hidden;
}
@media (max-width: 991px) {
  .plan {
    border-width: 1px;
  }
}

.col-md-4.plan:first-child {
  margin-right: -5px;
  margin-left: 5px;
}
@media (max-width: 991px) {
  .col-md-4.plan:first-child {
    margin: 0 0 15px 0;
  }
}

.plan:last-child {
  border-width: 1px;
}

.plan-title {
  position: relative;
  margin: -25px -25px 30px -25px;
  padding: 30px 20px 1px 20px;
  border-bottom: 1px dotted #d2d8dd;
  font-size: 15px;
  font-size: 0.9375rem;
  background-color: #f9f9f9;
  line-height: 1.2;
  color: #777;
}
.plan-title h3 {
  margin-bottom: 0;
}

.plan-tall .plan-title {
  background-color: #fc5b62;
  border-bottom: none;
  color: #fff;
}
.plan-tall .plan-title h3 {
  color: #fff;
}

.plan-price {
  margin: 0 auto 15px;
  width: 110px;
  height: 110px;
  line-height: 115px;
  font-size: 30px;
  font-weight: 600;
  color: white;
  background: #777;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  -ms-border-radius: 100px;
  border-radius: 100px;
  display: inline-block;
  text-align: center;
}

.plan-price > span {
  font-size: 12px;
  font-weight: normal;
  color: rgba(255, 255, 255, 0.9);
}

.plan-features {
  margin-bottom: 20px;
  line-height: 2;
}

ul.plan-features {
  padding: 0;
  margin: 0 0 20px 0;
}

.plan-features li {
  list-style: none;
}

.plan-tall {
  margin: 0;
  background: #fff;
  border-radius: 10px;
  margin-right: -5px;
  position: relative;
  z-index: 100;
  border: solid #fc5b62;
  position: relative;
}

.plan-tall > .plan-price {
  height: 130px;
  width: 130px;
  line-height: 135px;
  font-size: 36px;
  background: #fc5b62;
  -webkit-border-radius: 130px;
  -moz-border-radius: 130px;
  -ms-border-radius: 130px;
  border-radius: 130px;
}

.plan-tall + .plan {
  border-left: 0;
}
@media (max-width: 991px) {
  .plan-tall + .plan {
    border-left: 1px solid #d2d8dd;
  }
}

/*-------- 3.12 Styles Switcher --------*/
#style-switcher {
  background: #fff;
  width: 205px;
  position: fixed;
  top: 205px;
  z-index: 99;
  left: -205px;
  border-radius: 0 0 4px 0;
  -webkit-box-shadow: 0 6px 15px rgba(0, 0, 0, 0.175);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.175);
}
#style-switcher h3 {
  color: #fff;
  font-size: 13px;
  margin: 10px 0;
  font-weight: 500;
  text-transform: none;
  letter-spacing: 0;
}
#style-switcher ul {
  list-style: none;
  padding: 0;
  margin-bottom: 22px;
}
#style-switcher div {
  padding: 6px 18px;
}
#style-switcher h6 {
  background: #f8f8f8;
  color: #333;
  font-size: 14px;
  line-height: 46px;
  padding: 0 0 0 20px;
  margin-top: 0;
  font-weight: 500;
}
#style-switcher h6 a {
  background-color: #f8f8f8;
  display: block;
  height: 46px;
  position: absolute;
  right: -46px;
  top: 0;
  width: 46px;
  border-radius: 0 4px 4px 0;
  font-size: 22px;
  color: #fff;
  text-align: center;
}
#style-switcher h6 a i {
  position: relative;
  top: 4px;
  color: #333;
}

.colors {
  list-style: none;
  margin: 0 0px 10px 0;
  overflow: hidden;
}

.colors li {
  float: left;
  margin: 4px 2px 0 2px;
}

.colors li a {
  display: block;
  width: 17px;
  height: 17px;
  cursor: pointer;
}

.default {
  background: #fc5b62;
}

.aqua {
  background: #1cbbb4;
}

.green_switcher {
  background: #82ca9c;
}

.orange {
  background: #f7941d;
}

.blue {
  background: #004dda;
}

.colors li a.active {
  box-shadow: 0 0 0 2px #fff inset;
}

.colors li a {
  border-radius: 3px;
}

@media only screen and (max-width: 1024px) {
  #style-switcher {
    display: none;
  }
}
/*-------- 3.13 Time picker --------*/
@media only screen and (min-width: 992px) {
  .clock-timepicker-popup {
    position: absolute !important;
    left: 0 !important;
    top: 50px !important;
  }
}
