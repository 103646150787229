/* Body Scroll */
::-webkit-scrollbar {
	width: 10px;
}

::-webkit-scrollbar-track {
	background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
	background: #888;
	border-radius: 50px;
}

::-webkit-scrollbar-thumb:hover {
	background: #555;
}

/* Btn 2 */
a.btn_2,
.btn_2 {
	border: none;
	color: #fff;
	background: #0054a6;
	outline: none;
	cursor: pointer;
	display: inline-block;
	text-decoration: none;
	/* padding: 15px 30px; */
	padding: 11px 40px;
	color: #fff;
	font-weight: 600;
	text-align: center;
	line-height: 1;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-webkit-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	-ms-border-radius: 3px;
	border-radius: 3px;
}
a.btn_2:hover,
.btn_2:hover {
	background: #fc5b62;
	color: #fff;
}
a.btn_2.full-width,
.btn_2.full-width {
	display: block;
	width: 100%;
	text-align: center;
	margin-bottom: 5px;
}
a.btn_2.full-width.purchase,
.btn_2.full-width.purchase {
	background-color: #32a067;
}
a.btn_2.full-width.purchase:hover,
.btn_2.full-width.purchase:hover {
	background-color: #fc5b62;
}
a.btn_2.full-width.wishlist,
.btn_2.full-width.wishlist {
	border-color: #555;
	color: #555;
}
a.btn_2.medium,
.btn_2.medium {
	font-size: 16px;
	font-size: 1rem;
	padding: 18px 40px;
}
a.btn_2.rounded,
.btn_2.rounded {
	-webkit-border-radius: 25px !important;
	-moz-border-radius: 25px !important;
	-ms-border-radius: 25px !important;
	border-radius: 25px !important;
}
a.btn_2.outline,
.btn_2.outline {
	border: 2px solid #0054a6;
	color: #0054a6;
	padding: 11px 40px;
	background: none;
}
a.btn_2.outline:hover,
.btn_2.outline:hover {
	background: #fc5b62;
	color: #fff;
	border-color: #fc5b62;
}
a.btn_2.outline:focus,
.btn_2.outline:focus {
	outline: none;
}

.secondary_nav ul li:last-child {
	display: inline-block;
}

.secondary_nav ul li:last-child a.reviews {
	background-color: #fff;
	color: #000;
	opacity: 0.5;
}

.secondary_nav ul li:hover a,
.secondary_nav ul li:last-child:hover a {
	color: #fc5b62;
	opacity: 1;
}

.secondary_nav_stuck {
	position: fixed;
	top: 100px;
	width: 1263.33px;
}

.secondary_nav a.active {
	color: #fc5b62 !important;
}

.privacy_policy_table_heading {
	width: 30%;
	font-weight: 500;
	font-size: 16px;
}

.privacy_policy_table_primary_badge {
	background-color: unset !important;
	color: #0054a6 !important;
}

.privacy_policy_table_warning_badge {
	background-color: unset !important;
	color: #ffc107 !important;
}

.privacy_policy_table_success_badge {
	background-color: unset !important;
	color: #32a067 !important;
}

.privacy_policy_table_dark_badge {
	background-color: unset !important;
	color: #000 !important;
}

.privacy_policy_table tr {
	background-color: rgba(0, 0, 0, 0.025);
}

.privacy_policy_table tr td {
	padding: 15px;
}

.custom-select-form .nice-select:active,
.custom-select-form .nice-select.open,
.custom-select-form .nice-select:focus,
.form-group .form-control:focus {
	box-shadow: none;
	border: 1px solid #0054a6;
}

.text_arae {
	height: 150px;
	resize: none;
}

.menu_header_item {
	color: #fff;
}

header.sticky .menu_header_item {
	color: #000 !important;
}

.header_dropdown_icons {
	font-size: 16px;
}

.menu_header_item:hover,
.menu_header_item:focus,
.menu_header_item.active,
header.sticky .menu_header_item:hover,
header.sticky .menu_header_item:focus,
header.sticky .menu_header_item.active,
.mm-listview li.active a {
	color: #fc5b62 !important;
}

.mm-menu.mm-offcanvas {
	width: 80%;
}

.menu_close_icon {
	font-size: 25px;
	margin-right: 20px;
	color: #000;
	opacity: 0.7;
}

.hamburger-box {
	margin-top: 10px;
}

/* Home Banner */
.hero_single.version_2 {
	background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
		url("../img/additional/home_banner.jpg") no-repeat;
	background-position: center;
	-webkit-background-size: 100% 100%;
	-moz-background-size: 100% 100%;
	-o-background-size: 100% 100%;
	background-size: 100% 100%;
	height: 620px !important;
}

.bg_color_2 {
	background-color: lightslategrey;
}

.height_100 {
	height: 100px;
}

.featured_card_star_icon {
	font-size: 16px;
}

.featured_card_star_icon.yellow {
	color: #ffc107;
}

.featured_card_star_icon.gray {
	color: #ccc;
}

.featured_card_location_icon {
	font-size: 20px;
}

.book_now_button_wrapper {
	border-top: 1px solid #ededed;
	padding: 10px 15px;
}

.footer_website_link {
	transition: all 0.3s ease-in-out;
}

.footer_website_link:hover,
.footer_website_link:focus {
	color: #fc5b62;
	text-decoration: underline;
}

.font_weight_bold {
	font-weight: bold;
}

.font_weight_medium {
	font-weight: 600;
}

.font_weight_normal {
	font-weight: 400;
}

.btn_home_align {
	margin-right: 35px;
}

.detail_image {
	margin-bottom: 10px;
	width: 100%;
	height: 450px;
	border-radius: 10px;
}

.form_image {
	margin-bottom: 10px;
	width: 100%;
	height: 100px;
	border-radius: 10px;
}

.invoice_image {
	width: 100%;
	/* height: 80px; */
	border-radius: 10px;
}

.fs_15 {
	font-size: 15px !important;
}

.mb_12 {
	margin-bottom: 12px !important;
}

span.price_detail {
	display: inline-block;
	font-weight: 500;
	color: #999;
}

span.price_detail strong {
	color: #32a067;
}

h3.price_detail {
	font-size: 16px !important;
	display: inline-block;
	font-weight: 500;
	color: #999;
}

h3.price_detail strong {
	color: #32a067;
}

.footer_social_media_icon {
	height: 43px;
	width: 43px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 100%;
	transition: all 0.5s ease-in-out;
	border: 2px solid #fff;
	padding: 8px;
}

.footer_social_media_icon .svg:hover,
.footer_social_media_icon:hover,
.footer_social_media_icon:focus {
	background-color: #fc5b62;
	border: unset;
	color: #fff;
}

#description,
#reviews {
	border-bottom: unset !important;
}

.login_signup {
	display: none;
}

.padding_30_30 {
	padding: 30px 0;
}

.text_left {
	text-align: left !important;
}

.cursor_pointer {
	cursor: pointer;
}

/* HOME FILTER */
.panel-dropdown.categories .panel-dropdown-content {
	width: 170px;
}

.panel-dropdown.tours .panel-dropdown-content {
	width: 190px;
	height: 200px;
	overflow-y: scroll;
}

.active_dropdown_item {
	background-color: #fc5b62;
	padding-left: 5px;
	border-radius: 5px;
}

.active_dropdown_item label {
	color: #fff;
}

.radio_filter {
	border-radius: 100% !important;
	height: 15px !important;
	width: 15px !important;
}

.check_box {
	height: 15px !important;
	width: 15px !important;
}

.container_check .checkmark:after {
	display: none !important;
}

.filter_button_bottom_wrapper {
	border-top: 1px solid #ededed;
	padding: 15px 0;
}

.main-menu ul li > ul {
	min-width: 100px !important;
}

.hamburger {
	float: right !important;
}

.text_justify {
	text-align: justify;
}

.mail_share {
	border: 1px solid #7d7d7d !important;
	color: #7d7d7d !important;
}

.mail_share:hover {
	color: #fff !important;
	background-color: #7d7d7d;
}

.mail_share svg {
	margin: 0 3px 3px 0;
}

.whatsapp_share {
	border: 1px solid #32a067 !important;
	color: #32a067 !important;
}

.whatsapp_share:hover {
	color: #fff !important;
	background-color: #32a067;
}

.whatsapp_share svg {
	margin: 0 3px 3px 0;
}

.linkedin_share {
	display: flex;
	border: 1px solid #0054a6 !important;
	color: #0054a6 !important;
}

.linkedin_share:hover {
	color: #fff !important;
	background-color: #0054a6;
}

.linkedin_share svg {
	margin: 0 3px 3px 0;
}

/* Available Rooms */
.available_rooms_row {
	background-color: lightgrey;
}

.available_rooms_row .card_image {
	border: 1px solid #ddd;
	padding: 2px;
	width: 100%;
	max-height: 120px;
}

.available_rooms_row .man_icon {
	font-size: 22px;
}

.available_rooms_row .child_icon {
	font-size: 18px;
}

.height_250 {
	height: 210px;
}

.btn_1_detail,
.cancel {
	padding: 11px 35px !important;
}

.empty_rooms_text {
	font-weight: 500;
	font-size: 16px;
}

.available_rooms_row .btn_1.book_now {
	background-color: #32a067;
}

.available_rooms_row .btn_1.book_now:hover {
	background-color: #0054a6;
}

.disabled_button,
.disabled_button:hover,
.disabled_button:focus {
	pointer-events: unset !important;
	background-color: #32a067 !important;
	cursor: not-allowed !important;
}

.check_icon {
	color: #32a067;
	font-size: 20px;
	font-weight: bold;
	margin-right: 10px;
}

.calendar_icon {
	color: #32a067;
	/* font-size: 16px; */
	font-weight: bold;
	margin-right: 10px;
}

.cross_icon {
	color: #fc5b62;
	font-size: 17px;
	font-weight: bold;
	margin-right: 10px;
}

.tour_package_table_success_badge {
	background-color: #32a067 !important;
	color: #fff !important;
}

.tour_package_table_warning_badge {
	background-color: #ffc107 !important;
	color: #000 !important;
}

/* Default Modal */
.default_modal .modal-content {
	width: 350px;
	box-shadow: 0px 0px 19px 2px rgba(0, 0, 0, 0.25);
	border-radius: 15px;
	text-align: center !important;
}

.default_modal .modal-dialog-centered {
	justify-content: center !important;
}

.default_modal .modal-body {
	padding: 15px 30px;
}

.default_modal .modal_header {
	border-top-left-radius: 15px;
	border-top-right-radius: 15px;
	background-color: #f6f6f6 !important;
}

.default_modal .modal_header_close_button {
	color: #666;
	background-color: #e4e4e4;
	border-radius: 50%;
	top: 12px;
	right: 20px;
	width: 25px;
	height: 25px;
	line-height: 25px;
	cursor: pointer;
	padding: 5px;
	transition: all 0.3s ease-in-out;
}

.default_modal .modal_header_close_button:hover,
.default_modal .modal_header_close_button:focus {
	background-color: #fc5b62;
	color: #fff;
}

.default_modal h3.heading {
	font-size: 25px !important;
}

/* Tour Package Form Modal */
.tour_package_booking_form_modal .modal-content {
	/* width: 750px; */
	box-shadow: 0px 0px 19px 2px rgba(0, 0, 0, 0.25);
	border-radius: 15px;
	text-align: center !important;
}

.tour_package_booking_form_modal .modal-dialog-centered {
	justify-content: center !important;
}

.tour_package_booking_form_modal .modal-body {
	padding: 15px 30px;
}

.tour_package_booking_form_modal .modal_header {
	border-top-left-radius: 15px;
	border-top-right-radius: 15px;
	background-color: #f6f6f6 !important;
}

.tour_package_booking_form_modal .modal_header_close_button {
	color: #666;
	background-color: #e4e4e4;
	border-radius: 50%;
	top: 12px;
	right: 20px;
	width: 25px;
	height: 25px;
	line-height: 25px;
	cursor: pointer;
	padding: 5px;
	transition: all 0.3s ease-in-out;
}

.tour_package_booking_form_modal .modal_header_close_button:hover,
.tour_package_booking_form_modal .modal_header_close_button:focus {
	background-color: #fc5b62;
	color: #fff;
}

.privacy_policy_table_sun_icon {
	margin-right: 5px;
	color: #32a067;
	font-size: 20px;
	margin-top: 3px;
}

.sun_icon,
.users_icon {
	margin-right: 10px;
	color: #32a067;
	font-size: 18px;
}

.check_square_icon {
	font-size: 16px;
	color: #0054a6;
	margin-right: 7px;
}

.privacy_policy_table_moon_icon {
	margin-right: 5px;
	color: #ffc107;
	font-size: 18px;
	margin-top: 3px;
}

/* Tabs */
.nav-tabs {
	background-color: #f8f9fa;
}

.nav-tabs .nav-link,
.nav-tabs .nav-link:focus {
	border: none;
	font-size: 16px;
	color: rgba(0, 0, 0, 0.5) !important;
	font-weight: 500;
	margin-left: 5px;
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link.active {
	background-color: unset !important;
	color: #0054a6 !important;
	opacity: 1;
	border-bottom: 2px solid #0054a6;
}

.tab-content {
	background-color: #f8f9fa;
}

.tab-content .tab-pane {
	padding: 20px;
}

.btn_2.apply_coupon {
	background-color: #32a067;
}

.btn_2.apply_coupon:hover,
.btn_2.apply_coupon:focus {
	background-color: #ffc107;
}

.vertical_align_middle {
	vertical-align: middle;
}

.switch_toggle input {
	width: 50px !important;
	height: 25px !important;
	cursor: pointer;
	box-shadow: none !important;
	border-color: #0054a6 !important;
}

.switch_toggle input::before {
	color: #0054a6 !important;
}

.switch_toggle input:checked {
	background-color: #0054a6 !important;
}

/* AUTH */
#login_bg,
#register_bg {
	background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
		url("../img/additional/home_banner.jpg") no-repeat;
	background-position: center;
	background-size: cover;
}

#login aside {
	padding: 40px 60px 0 60px;
}

#login aside form .form-group input,
#register aside form .form-group input {
	padding-left: 15px;
}

#login aside form .contact_no_input .form-control {
	padding-left: 50px;
	margin-right: 0;
}

.react-tel-input .selected-flag:before {
	box-shadow: none !important;
	border: 0 !important;
}

.react-tel-input .country-list .search-box {
	width: 95% !important;
}

.contact_no_input .form-control {
	padding: 10px;
    height: 42px;
	width: 100% !important;
    font-size: 14px;
    font-size: 0.875rem;
    border-radius: 3px !important;
    border: 1px solid #d2d8dd;
    color: #495057;
	box-shadow: none !important;
  }

  .contact_no_input .form-control:focus {
	border-color: #0054a6 !important;
  }

/* Mobile Screes */
@media only screen and (max-width: 600px) {
	.btn_home_align {
		margin-right: 0;
	}

	.login_signup {
		display: block;
		text-align: right;
		margin-top: 10px;
		margin-right: 20px;
	}

	header.header.sticky {
		height: 70px;
	}

	.height_250 {
		height: 230px;
	}
}

@media (max-width: 991px) {
	#logo {
		float: none;
		position: absolute;
		top: 0;
		left: unset;
		width: 100%;
		text-align: left;
	}

	#logo img {
		width: auto;
		height: 50px;
		/* margin: 12px 0 0 0; */
	}

	.main-menu {
		right: 50px !important;
	}
}
